import { createSlice } from "@reduxjs/toolkit";
import { NotifyInitialStateTypes, NotifyType } from "../../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

const initialState: NotifyInitialStateTypes = {
    notify: {
        notifyId: "",
        type: "",
        title: "",
        subTitle: "",
        duration: 5000,
        configs: {},
    },
};

const notifySlice = createSlice({
    name: "notify",
    initialState,
    reducers: {
        showNotifyRedux(state, action: PayloadAction<NotifyType>) {
            const notify: NotifyType = {
                ...state.notify,
                ...action.payload,
                configs: action.payload.configs || {},
                notifyId: uuid(),
            };
            const inviteDuplicate = action.payload.configs?.invite && state.notify.configs?.invite;
            state.notify = inviteDuplicate ? state.notify : notify;
        },
        hideNotifyRedux(state) {
            state.notify = initialState.notify;
        },
        resetNotifyConfigsRedux(state) {
            state.notify.configs = initialState.notify.configs;
        },
    },
});

export const { showNotifyRedux, hideNotifyRedux, resetNotifyConfigsRedux } = notifySlice.actions;
export default notifySlice.reducer;
