import { ConstantsTypes } from "../../types/index";

export const constants: Readonly<ConstantsTypes> = {
    bearer: "Bearer ",
    masq: "MASQ",
    emailPattern: /^[\w\.-]{3,}@[\w\.-]+\.[a-zA-Z]{2,}$/,
    passwordPattern: new RegExp("^[A-Za-z!@#$%^&*()\\-_+=;:,./\\`~{}0-9]{1,}$"),
    yes: "yes",
    no: "no",
    masqWallet: "MASQ",
    create_new_team: "Create new team",
    convert: "Convert",
    merge: "Merge",
    paths: {
        home: "/",
        signIn: "/signIn",
        signUp: "/signUp",
        account: "/account",
        tariff: "/tariff",
        terms_and_privacy: "/terms_and_privacy",
    },
    endpoints: {
        userLogin: "/v2/user/login",
        registration: "/v2/user/registration",
        userAuth: "/v2/user/auth/",
        confirmEmail: "/v2/user/confirmEmail/",
        checkNameFree: "/v2/user/checkNameFree/",
        sendConfirmCode: "/v2/user/sendConfirmCode",
        sendRecoveryCode: "/v2/user/sendRecoveryCode",
        checkRecoveryCode: "/v2/user/checkRecoveryCode",
        recoveryPassword: "/v2/user/recoveryPassword",
        logOut: "/v2/user/logout/",
        changeName: "/v2/user/changeName/",
        deleteAccount: "/v2/user/deleteAccount/",
        tariffList: "/v2/tariff/list/",
        removeTfa: "/v2/user/tfa/remove",
        removeOtherSessions: "/v2/user/removeOtherSessions/",
        changePassword: "/v2/user/changePassword/",
        verifyTfa: "/v2/user/tfa/verify",
        setupTfa: "/v2/user/tfa/setup",
        changeEmailSendConfirmCode: "/v2/user/changeEmail/sendConfirmCode",
        confirmCurrentEmail: "/v2/user/changeEmail/confirmCurrentEmail/",
        setNewEmail: "/v2/user/changeEmail/setNewEmail/",
        confirmNewEmail: "/v2/user/changeEmail/confirmNewEmail/",
        promoCode: "/v2/promoCode/",
        buySubscriptionPlan: "/v2/sub/buy/",
        paymentsTariff: "/v2/cryptomus/paymentSub/",
        paymentsTariffTeam: "/v2/cryptomus/paymentTeamSub",
        getCryptomusCurrencies: "/v2/cryptomus/currencies",
        buyTeamPlan: "/v2/team/sub/buy/",
        changeTariff: "/v2/sub/changeTariff/",
    },
    fontFamilyTypes: {
        thin: "Geo-100",
        extraLight: "Geo-200",
        light: "Geo-300",
        regular: "Geo-400",
        medium: "Geo-500",
        semiBold: "Geo-600",
        bold: "Geo-700",
        extraBold: "Geo-800",
        black: "Geo-900",
    },
    modalTypes: {
        download: "DOWNLOAD",
        settings: "SETTINGS",
        deleteAcc: "DELETE ACCOUNT",
        security: "SECURITY",
        endAllSession: "END ALL SESSION",
        passwordChange: "CHANGE PASSWORD",
        tfa: "TFA",
        emailChange: "CHANGE EMAIL",
        useQrCode: "Use Payment Qr Code",
        chooseSubPlanMethod: "Merge or Convert Subscription Plan",
        convertSubs: "Convert Subs",
        moreBenefitsShow: "moreBenefitsShow",
    },
    notifyTypes: {
        success: "#0F9918",
        invite: "#560BAD",
        error: "#E50000",
    },
};
