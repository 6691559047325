import { Collapse } from "antd";
import { CollapseDataTypes } from "../../types";
import Star from "../../assets/images/svg/Star";
import "./collapse_stars.scss";

const CollapseStars = (props: CollapseDataTypes) => {
    return (
        <Collapse
            activeKey={props.activityKey}
            ghost
            accordion
            expandIcon={(panelProps) => <Star isActive={!!panelProps.isActive} />}
            className="collapse_star_wrapper"
            items={props.list}
            // onChange={(e) => props.setActivityKey && props.setActivityKey(e[0])}
        />
    );
};

export default CollapseStars;
