import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { helpers } from "../../helpers";
import { loginByTokenAsync } from "../../store/features/accountSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { constants } from "../../assets/constants";
import { getTariffListAsync } from "../../store/features/tariffSlice";
import { useTranslation } from "react-i18next";

const Listeners = () => {
    const dispatch = useAppDispatch();
    const { getLanguage } = helpers;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { paths } = constants;
    const { i18n } = useTranslation();
    const {
        user: { emailConfirmed },
    } = useAppSelector((state) => state.account);

    useEffect(() => {
        const token = localStorage.getItem("token");
        localStorage.removeItem("try_now_value");
        !!token && dispatch(loginByTokenAsync({ token }));
    }, []);
    useEffect(() => {
        dispatch(getTariffListAsync());
    }, []);
    useEffect(() => {
        // pathname === paths.signIn && emailConfirmed && navigate(paths.account);
        if (emailConfirmed && localStorage.getItem("try_now_value")) {
            navigate(paths.tariff);
        }
    }, [pathname, emailConfirmed]);

    useEffect(() => {
        const selectedLng = getLanguage();
        if (selectedLng) {
            i18n.changeLanguage(selectedLng);
        }
    }, []);

    return <></>;
};

export default Listeners;
