import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { coming_delay, opacity_scale_anime } from "../../../../assets/animations";
import SectionTitle from "../../../../helperComponents/SectionTitile";
import GrainyGradient from "../../../../helperComponents/GrainyGradient";
import styles from "./each_profile.module.scss";

const EachProfile = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.each_profile_wrapper}>
            <GrainyGradient />
            <div className={styles.each_profile_main}>
                <div className={styles.info_wrapper}>
                    <SectionTitle text={t("eachProfile.title")} />
                    <motion.p
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={coming_delay}
                        custom={0.3}
                        className={styles.each_profile_text}
                    >
                        {t("eachProfile.text")}
                    </motion.p>
                </div>
                <div className={styles.profiles_wrapper}>
                    <div className={styles.first_line}>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={0.1}>
                            {t("sundry.platform")}
                        </motion.p>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={0.2}>
                            {t("sundry.os")}
                        </motion.p>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={0.3}>
                            {t("sundry.resolution")}
                        </motion.p>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={0.4}>
                            {t("sundry.browser")}
                        </motion.p>
                    </div>
                    <div className={styles.second_line}>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={0.5}>
                            {t("sundry.country")}
                        </motion.p>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={0.6}>
                            {t("sundry.timezone")}
                        </motion.p>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={0.7}>
                            {t("sundry.language")}
                        </motion.p>
                    </div>
                    <div className={styles.third_line}>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={0.8}>
                            {t("sundry.browser_version")}
                        </motion.p>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={0.9}>
                            {t("sundry.ram")}
                        </motion.p>
                        <motion.p initial="hidden" whileInView="visible" viewport={{ once: true }} variants={opacity_scale_anime} custom={1}>
                            {t("sundry.cpu")}
                        </motion.p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EachProfile;
