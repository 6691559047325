const MenuIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
        <g stroke="#14161F" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={1.5} clipPath="url(#a)">
            <path d="M15.375 5.104h-4.958M7.583 12.896H2.625M13.25 9h-8.5" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.5.5h17v17H.5z" />
            </clipPath>
        </defs>
    </svg>
);
export default MenuIcon;
