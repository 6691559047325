const GoogleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={84} height={84} fill="none">
        <rect width={82.476} height={82.476} x={0.5} y={0.5} fill="#fff" rx={41.238} />
        <rect width={82.476} height={82.476} x={0.5} y={0.5} stroke="#EEE" rx={41.238} />
        <path
            fill="#14161F"
            fillRule="evenodd"
            d="M58.117 42.138c0-1.245-.112-2.443-.32-3.592H41.258v6.793h9.451c-.407 2.195-1.644 4.055-3.504 5.3v4.406h5.675c3.321-3.057 5.237-7.559 5.237-12.907Z"
            clipRule="evenodd"
        />
        <path
            fill="#14161F"
            fillRule="evenodd"
            d="M41.257 59.3c4.741 0 8.717-1.573 11.622-4.255l-5.675-4.406c-1.573 1.053-3.584 1.676-5.947 1.676-4.574 0-8.445-3.09-9.826-7.24h-5.867v4.55c2.89 5.74 8.828 9.675 15.693 9.675Z"
            clipRule="evenodd"
        />
        <path
            fill="#14161F"
            fillRule="evenodd"
            d="M31.43 45.075a10.557 10.557 0 0 1-.55-3.337c0-1.157.2-2.282.55-3.336v-4.55h-5.866a17.554 17.554 0 0 0-1.868 7.886c0 2.834.678 5.516 1.868 7.887l5.867-4.55Z"
            clipRule="evenodd"
        />
        <path
            fill="#14161F"
            fillRule="evenodd"
            d="M41.257 31.162c2.578 0 4.893.886 6.713 2.626l5.037-5.037c-3.041-2.834-7.017-4.574-11.75-4.574-6.865 0-12.804 3.936-15.693 9.675l5.866 4.55c1.382-4.151 5.253-7.24 9.827-7.24Z"
            clipRule="evenodd"
        />
    </svg>
);
export default GoogleIcon;
