import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { constants } from "../../../../assets/constants";
import { resetByDefaultAccountRedux, sendRecoveryCodeAsync } from "../../../../store/features/accountSlice";
import { SignInPagesProps } from "../../../../types";
import Input from "../../../../helperComponents/Input";
import styles from "./recovery_email.module.scss";
import { useEffect } from "react";

const RecoveryEmail = ({ formData, changeFormData }: SignInPagesProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { recoveryEmail } = useAppSelector((state) => state.account);
    const { emailPattern } = constants;

    const sendRecoveryCode = () => {
        dispatch(sendRecoveryCodeAsync({ name: formData.nameEmail }));
        changeFormData("nameEmail", formData.nameEmail);
    };

    const backToMainPage = () => {
        changeFormData({ type: "page", value: "0" });
        dispatch(resetByDefaultAccountRedux());
    };

    useEffect(() => {
        recoveryEmail && changeFormData({ type: "page", value: "2" });
    }, [recoveryEmail]);

    return (
        <>
            <p className={styles.title}>{t("signIn.reset_password")}</p>
            <p className={styles.subTitle}>{t("signIn.reset_pass_subtitle")}</p>
            <Input
                label={t("signIn.username_or_email")}
                type="text"
                placeholder={t("signIn.username_or_email")}
                value={formData.nameEmail}
                onChange={changeFormData}
                name="nameEmail"
            />
            <div className={styles.btns_wrapper}>
                <button className={styles.back} onClick={backToMainPage}>
                    {t("sundry.back")}
                </button>
                <button
                    className={styles.create_acc}
                    onClick={sendRecoveryCode}
                    disabled={!(formData.nameEmail.includes("@") ? emailPattern.test(formData.nameEmail) : formData.nameEmail.length > 2)}
                >
                    {t("sundry.send_code")}
                </button>
            </div>
        </>
    );
};

export default RecoveryEmail;
