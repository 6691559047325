import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChangeFormDataTypes, SignUpFormDataTypes } from "../../types";
import { constants } from "../../assets/constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { checkNameFreeAsync, resetFormErrorsRedux } from "../../store/features/signupSlice";
import { helpers } from "../../helpers";
import { Link, useNavigate } from "react-router-dom";
import MASQLogo from "../../helperComponents/MASQLogo";
import SectionSubtitle from "../../helperComponents/SectionSubtitle";
import SectionTitle from "../../helperComponents/SectionTitile";
import styles from "./sign_up.module.scss";
import UsernameEmail from "./pages/UsernameEmail";
import Confirmation from "./pages/Confirmation";

const signUpInitialState: SignUpFormDataTypes = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmCode: "",
    page: "0",
    nameLocalError: "",
    emailLocalError: "",
    passwordLocalError: "",
};

const SignUp = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { nameError, emailError } = useAppSelector((state) => state.signUp);

    const { emailPattern, paths, passwordPattern } = constants;

    const [formData, setFormData] = useState(signUpInitialState);

    const changeFormData = ({ type, value }: ChangeFormDataTypes) => {
        (nameError || emailError) && dispatch(resetFormErrorsRedux({ type }));
        let err = "";
        if (type === "name" && value.length < 3 && value.length) {
            err = t("signUp.name_local_error");
        } else if (type === "email" && !emailPattern.test(value) && value.length) {
            err = t("signUp.email_local_error");
        }
        if (type === "name" && !helpers.isOnlyCyrillic(value)) return;
        if (type === "password" && value) {
            if (value && !passwordPattern.test(value)) return;
            if (value.length < 8) err = t("signUp.enter_password");
        }
        setFormData({ ...formData, [type]: value, [type + "LocalError"]: err });
    };

    const signUpPages = useMemo(
        () => ({
            "0": <UsernameEmail formData={formData} changeFormData={changeFormData} />,
            "1": <Confirmation email={formData.email} changeFormData={changeFormData} pageValue="0" />,
        }),
        [formData]
    );

    const currentPage = signUpPages[formData.page as keyof typeof signUpPages];

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (formData.name.length > 2 || emailPattern.test(formData.email)) {
            timeout = setTimeout(() => {
                dispatch(checkNameFreeAsync({ name: formData.name, email: formData.email }));
            }, 300);
        }
        return () => clearTimeout(timeout);
    }, [formData.name, formData.email]);

    useEffect(() => {
        !+formData.page && setFormData(signUpInitialState);
    }, [formData.page]);

    return (
        <div className={styles.sign_up_wrapper}>
            <div className={styles.signUp_left}>
                <div className={styles.signUp_left_main}>
                    <Link to={paths.home}>
                        <MASQLogo isWhite={true} />
                    </Link>
                    <div className={styles.info_wrapper}>
                        <SectionSubtitle text={t("andtidetected.newEra")} />
                        <SectionTitle text={t("andtidetected.andtidetectedBrowser")} style={{ fontSize: "80px", color: "#FFF" }} />
                        <p className={styles.info}>{t("andtidetected.making_the_work")}</p>
                    </div>
                </div>
            </div>
            <div className={styles.signUp_right}>
                <div className={styles.sign_up_right_main}>{currentPage}</div>
            </div>
        </div>
    );
};

export default SignUp;
