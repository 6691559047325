import { useTranslation } from "react-i18next";
import styles from "./footer.module.scss";
import { Link } from "react-router-dom";
import { constants } from "../../../assets/constants";

const Footer = () => {
    const { t } = useTranslation();
    const thisYear = new Date().getFullYear();

    return (
        <footer className={styles.private_footer}>
            <div className={styles.footer_date}>
                <p className={styles.date}>
                    {t("footer.date")}
                    {thisYear} {t("sundry.masq")}
                </p>
                <div className={styles.links}>
                    <Link to={constants.paths.terms_and_privacy} target="_blank">
                        {t("sundry.terms_and_privacy")}
                    </Link>
                    <a href={process.env.REACT_APP_TELEGRAM_LINK} target="_blank">
                        {t("sundry.telegram")}
                    </a>
                    <a href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}`}>{t("sundry.mail")}</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
