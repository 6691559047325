const AmazonIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={61} height={61} fill="none">
        <rect width={60} height={60} x={0.5} y={0.5} fill="#fff" rx={30} />
        <rect width={60} height={60} x={0.5} y={0.5} stroke="#EEE" rx={30} />
        <path
            fill="#14161F"
            d="M38.42 37.848c-8.932 4.613-14.476.754-18.024-1.59-.22-.148-.593.034-.27.438C21.31 38.25 25.184 42 30.24 42c5.06 0 8.072-2.996 8.448-3.519.374-.518.11-.804-.268-.633Zm2.508-1.503c-.24-.34-1.458-.402-2.225-.3-.768.1-1.921.608-1.82.914.05.115.155.064.683.012.53-.057 2.012-.26 2.32.178.31.441-.472 2.543-.615 2.882-.138.339.053.426.312.2.256-.225.72-.81 1.03-1.637.31-.831.498-1.991.316-2.25Z"
        />
        <path
            fill="#14161F"
            fillRule="evenodd"
            d="M32.629 29.498c0 1.16.029 2.126-.548 3.155-.465.837-1.203 1.351-2.026 1.351-1.125 0-1.78-.87-1.78-2.154 0-2.536 2.237-2.996 4.354-2.996v.644Zm2.953 7.251a.604.604 0 0 1-.692.071c-.972-.82-1.145-1.2-1.68-1.983C31.603 36.502 30.467 37 28.382 37 25.92 37 24 35.456 24 32.364c0-2.414 1.29-4.058 3.122-4.862 1.59-.711 3.81-.836 5.507-1.033v-.385c0-.707.053-1.544-.354-2.155-.359-.548-1.042-.774-1.644-.774-1.116 0-2.113.582-2.356 1.787-.05.267-.243.531-.506.544l-2.842-.31c-.24-.054-.503-.251-.437-.623C25.145 21.054 28.255 20 31.04 20c1.425 0 3.287.385 4.411 1.482 1.425 1.35 1.29 3.154 1.29 5.117v4.636c0 1.393.568 2.004 1.103 2.757.19.268.23.59-.008.79-.597.507-1.66 1.448-2.245 1.976l-.008-.009"
            clipRule="evenodd"
        />
    </svg>
);
export default AmazonIcon;
