import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store";
import { setModalTypeRedux } from "../../../../store/features/modalSlice";
import { ReactComponent as Fingerprint } from "../../../../assets/images/svg/fingerprintSVG/Fingerprint.svg";
import { ReactComponent as FingerprintBorder } from "../../../../assets/images/svg/fingerprintSVG/Union.svg";
import { ReactComponent as FingerprintBackground } from "../../../../assets/images/svg/fingerprintSVG/fingerprint_background.svg";
import { motion } from "framer-motion";
import { big_scale_animie, coming_from_top_anime, opacity_anime, opacity_scale_anime, small_scale_animie } from "../../../../assets/animations";
import { constants } from "../../../../assets/constants";
import SectionSubtitle from "../../../../helperComponents/SectionSubtitle";
import SectionTitle from "../../../../helperComponents/SectionTitile";
import EbayIcon from "../../../../assets/images/svg/fingerprintSVG/EbayIcon";
import FacebookIcon from "../../../../assets/images/svg/fingerprintSVG/FacebookIcon";
import AmazonIcon from "../../../../assets/images/svg/fingerprintSVG/AmazonIcon";
import GoogleIcon from "../../../../assets/images/svg/fingerprintSVG/GoogleIcon";
import styles from "./fingerprints.module.scss";

const Fingerprints = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { modalTypes } = constants;

    const openModal = () => dispatch(setModalTypeRedux({ type: modalTypes.download }));

    return (
        <div className={styles.fingerprints_wrapper}>
            <div className={styles.fingerprints_main}>
                <div className={styles.fingerprint_icon}>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={big_scale_animie}
                        custom={1.1}
                        className={styles.big_border}
                    >
                        <FingerprintBorder />
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={small_scale_animie}
                        custom={0.8}
                        className={styles.small_border}
                    >
                        <FingerprintBorder />
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_anime}
                        custom={0.5}
                        className={styles.fingerprint_background}
                    >
                        <FingerprintBackground />
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_anime}
                        custom={0.3}
                        className={styles.fingerprint}
                    >
                        <Fingerprint />
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_scale_anime}
                        custom={1.3}
                        className={styles.ebay_icon}
                    >
                        <EbayIcon />
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_scale_anime}
                        custom={1.5}
                        className={styles.fb_icon}
                    >
                        <FacebookIcon />
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_scale_anime}
                        custom={1.7}
                        className={styles.google_icon}
                    >
                        <GoogleIcon />
                    </motion.div>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_scale_anime}
                        custom={1.9}
                        className={styles.amazon_icon}
                    >
                        <AmazonIcon />
                    </motion.div>
                </div>
                <div className={styles.fingerprint_info_wrapper}>
                    <SectionTitle text={t("fingerprints.title")} style={{ fontFamily: "Geo-400", fontSize: "62px" }} />
                    <motion.p
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_anime}
                        custom={0.7}
                        className={styles.info}
                    >
                        {t("fingerprints.text")}
                    </motion.p>
                    <motion.button
                        initial="hidden"
                        whileInView="visible"
                        variants={coming_from_top_anime}
                        viewport={{ once: true }}
                        custom={100}
                        className={styles.try_it}
                        onClick={openModal}
                    >
                        {t("sundry.try_it")}
                    </motion.button>
                </div>
            </div>
        </div>
    );
};

export default Fingerprints;
