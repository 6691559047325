import React from "react";
import styles from "./pay_via_qr.module.scss";
import { useAppSelector } from "../../../../store";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";

const PayVIAQr = () => {
    const { t } = useTranslation();
    const { paymentTariffUrl } = useAppSelector((state) => state.account);
    return (
        <>
            <div className={styles.qr_code_wrapper}>
                <QRCode value={paymentTariffUrl} className={styles.qr_code} />
            </div>
            <ul className={styles.qr_info}>
                <li className={styles.info_item}>{t("buy_change_sub.scan_qr")}</li>
                <li className={styles.info_item}>{t("buy_change_sub.confirm_pay")}</li>
            </ul>
            <a href={paymentTariffUrl} target="_blank" className={styles.black_btn}>
                {t("buy_change_sub.pay_online")}
            </a>
        </>
    );
};

export default PayVIAQr;
