import { createSlice } from "@reduxjs/toolkit";
import { ModalTypes } from "../../types";
import { constants } from "../../assets/constants";

const { convert, merge } = constants;

const initialState: ModalTypes = {
    type: "",
    configs: {},
    methodForBuySub: convert,
    isMergeOrConvertPageOpen: false,
    moreBenefitsShow: "",
};

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setModalTypeRedux(state, action) {
            state.type = action.payload.type;
            state.configs = action.payload.configs;
            state.moreBenefitsShow = action.payload.moreBenefitsShow || "";
        },
        resetModalStateToInitialRedux(state) {
            state.type = initialState.type;
            state.configs = initialState.configs;
            state.methodForBuySub = initialState.methodForBuySub;
            state.isMergeOrConvertPageOpen = initialState.isMergeOrConvertPageOpen;
        },
        resetIsMergeOrConvertPageOpenRedux(state) {
            state.isMergeOrConvertPageOpen = false;
        },
        resetMoreBenefitsShowRedux(state) {
            state.moreBenefitsShow = initialState.moreBenefitsShow;
        },
        setHelperModalMethodToMerge(state) {
            state.isMergeOrConvertPageOpen = true;
            state.methodForBuySub = merge;
        },
        setHelperModalMethodForBuySubRedux(state, action) {
            state.methodForBuySub = action.payload;
            state.type = initialState.type;
            state.isMergeOrConvertPageOpen = true;
        },
        changeModalConfigsRedux(state, action) {
            state.configs = {
                ...state.configs,
                [action.payload.type]: action.payload.value,
            };
        },
    },
});

export const {
    setModalTypeRedux,
    resetModalStateToInitialRedux,
    setHelperModalMethodToMerge,
    setHelperModalMethodForBuySubRedux,
    changeModalConfigsRedux,
    resetIsMergeOrConvertPageOpenRedux,
    resetMoreBenefitsShowRedux,
} = modalSlice.actions;

export default modalSlice.reducer;
