import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { comeing_anime, opacity_anime } from "../../../../assets/animations";
import GrainyGradient from "../../../../helperComponents/GrainyGradient";
import styles from "./footer.module.scss";
import { Link } from "react-router-dom";
import { constants } from "../../../../assets/constants";

const Footer = () => {
    const { t } = useTranslation();
    const thisYear = new Date().getFullYear();
    return (
        <footer className={styles.footer}>
            <GrainyGradient />
            <div className={styles.footer_main}>
                <div className={styles.text_wrapper}>
                    <motion.p
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_anime}
                        custom={0.8}
                        className={styles.text}
                    >
                        {t("footer.text")}
                    </motion.p>
                    <motion.a
                        href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_anime}
                        custom={0.6}
                        className={styles.email}
                    >
                        {process.env.REACT_APP_EMAIL_ADDRESS}
                    </motion.a>
                </div>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={comeing_anime}
                    custom={-250}
                    className={styles.footer_date}
                >
                    <motion.p
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_anime}
                        custom={1}
                        className={styles.date}
                    >
                        {t("footer.date")}
                        {thisYear} {t("sundry.masq")}
                    </motion.p>
                    <div className={styles.links}>
                        <Link to={constants.paths.terms_and_privacy} target="_blank">
                            {t("sundry.terms_and_privacy")}
                        </Link>
                        <motion.a
                            initial="hidden"
                            href={process.env.REACT_APP_TELEGRAM_LINK}
                            target="_blank"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={opacity_anime}
                            custom={1.2}
                        >
                            {t("sundry.telegram")}
                        </motion.a>
                        <motion.a
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={opacity_anime}
                            custom={1.4}
                            href={`mailto:${process.env.REACT_APP_EMAIL_ADDRESS}`}
                        >
                            {t("sundry.mail")}
                        </motion.a>
                    </div>
                </motion.div>
            </div>
        </footer>
    );
};

export default Footer;
