import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { constants } from "../../../../assets/constants";
import { changeModalConfigsRedux, setHelperModalMethodForBuySubRedux } from "../../../../store/features/modalSlice";
import { Select } from "antd";
import styles from "./choose_plan_method.module.scss";

const ChoosePlanMethod = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { teams } = useAppSelector((state) => state.account);
    const { configs, methodForBuySub } = useAppSelector((state) => state.modal);
    const { create_new_team } = constants;
    const chooseMethodMenuItemsForTeam = useMemo(() => {
        const items = teams.map((team) => ({
            id: team._id,
            value: team.name,
            label: team.name,
        }));
        items.unshift({
            id: create_new_team,
            value: create_new_team,
            label: t("buy_change_sub.create_new_team"),
        });
        return items;
    }, [teams, t]);

    const [chooseMethod, setChooseMethod] = useState<string>(methodForBuySub);

    const changeChooseMethod = (value: string) => setChooseMethod(value);
    const changeChooseTeamId = (value: string) => {
        const teamId = chooseMethodMenuItemsForTeam.find((el) => el.value === value)?.id || create_new_team;
        dispatch(
            changeModalConfigsRedux({
                type: "renewTeamId",
                value: teamId,
            })
        );
    };

    const handleConvertOrBuySub = () => dispatch(setHelperModalMethodForBuySubRedux(chooseMethod));

    useEffect(() => {
        setChooseMethod(methodForBuySub);
    }, [methodForBuySub]);

    return (
        <div className={styles.choose_wrapper}>
            <p className={styles.info}>{t("convert_subs.choose_method_text_first")}</p>
            <Select
                options={chooseMethodMenuItemsForTeam}
                style={{ width: 250, zIndex: 9999 }}
                defaultValue={chooseMethodMenuItemsForTeam.find((el) => el.id === configs?.renewTeamId)?.value}
                onChange={!configs?.renewTeamId ? changeChooseMethod : changeChooseTeamId}
                className={styles.select}
            />
            <button className={styles.black_btn} onClick={handleConvertOrBuySub}>
                {t("sundry.next")}
            </button>
        </div>
    );
};

export default ChoosePlanMethod;
