import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { coming_from_top_anime, scale_anime } from "../../../../assets/animations";
import the_profile_system from "../../../../assets/images/howItWorks/the_profile_system.png";
import scenarios from "../../../../assets/images/howItWorks/scenarios.png";
import profile_grouping from "../../../../assets/images/howItWorks/profile_grouping.png";
import fast_data_exchange from "../../../../assets/images/howItWorks/fast_data_exchange.png";
import session_tracking from "../../../../assets/images/howItWorks/session_tracking.png";
import SectionTitle from "../../../../helperComponents/SectionTitile";
import CollapseNumbers from "../../../../helperComponents/CollapseNumbers";
import styles from "./how_it_works.module.scss";

const sliderImages = [the_profile_system, scenarios, profile_grouping, fast_data_exchange, session_tracking];

const HowItWorks = () => {
    const { t } = useTranslation();

    const collapseData = useMemo(
        () => [
            {
                key: "1",
                label: t("howItWorks.profile_system"),
                children: t("howItWorks.profile_system_text"),
            },
            {
                key: "2",
                label: t("howItWorks.scenarious"),
                children: t("howItWorks.scenarious_text"),
            },
            {
                key: "3",
                label: t("howItWorks.grouping"),
                children: t("howItWorks.grouping_text"),
            },
            {
                key: "4",
                label: t("howItWorks.fast_data"),
                children: t("howItWorks.fast_data_text"),
            },
            {
                key: "5",
                label: t("howItWorks.session_tracking"),
                children: t("howItWorks.session_tracking_text"),
            },
        ],
        [t]
    );
    const [activityKey, setActivityKey] = useState<number>(0);
    const scroll1 = () => {
        const coordinateA = window.innerWidth < 420 ? 7740 : 6610;
        const coordinateB = window.innerWidth < 420 ? 9000 : 7550;
        if (window.scrollY > coordinateA && window.scrollY < coordinateB) {
            const num = Math.floor((window.scrollY - coordinateA) / 200) + 1;
            if (activityKey !== num && num < 6) setActivityKey(num);
            if (num < 1) {
                setActivityKey(1);
            } else if (num > 5) {
                setActivityKey(6);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scroll1);
    }, []);

    return (
        <div className={styles.works_main_wrapper}>
            <div className={styles.works_wrapper}>
                <div className={styles.works_main}>
                    <SectionTitle text={t("howItWorks.title")} />
                    <div className={styles.info_wrapper}>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={coming_from_top_anime}
                            custom={-100}
                            className={styles.image}
                            style={{ position: "relative", top: (activityKey - 1) * 80 + "px" }}
                        >
                            {sliderImages.map((imgSrc, i) => {
                                return <img key={i} src={imgSrc} alt={`img-${i}`} style={{ opacity: activityKey - 1 === i ? 1 : 0 }} />;
                            })}
                        </motion.div>
                        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={scale_anime} className={styles.collapse_wrapper}>
                            <CollapseNumbers list={collapseData} activityKey={activityKey} />
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
