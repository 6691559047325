import React, { useEffect, useRef, useState } from "react";
import { SignInPagesProps } from "../../../../types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { constants } from "../../../../assets/constants";
import { useAppDispatch, useAppSelector } from "../../../../store";
import styles from "./recovery_confirmation.module.scss";
import VerificationInput from "react-verification-input";
import { checkRecoveryCodeAsync, resetInvalidConfirmCodeRedux, resetRecoveryEmail, sendRecoveryCodeAsync } from "../../../../store/features/accountSlice";

const RecoveryConfirmation = ({ formData, changeFormData }: SignInPagesProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isConfirmCodeInvalid, recoveryEmail } = useAppSelector((state) => state.account);

    const codeAgainBtn = useRef<HTMLDivElement>(null);
    const [confirmCode, setConfirmCode] = useState("");
    const [isSendCodeAgain, setIsSendCodeAgain] = useState(false);

    const sendRecoveryConfirmCodeAgain = () => {
        setIsSendCodeAgain(false);
        setConfirmCode("");
        dispatch(sendRecoveryCodeAsync({ name: formData.nameEmail }));
    };

    const toggleConfirmCode = (value: string) => {
        dispatch(resetInvalidConfirmCodeRedux());
        setConfirmCode(value.toUpperCase());
    };

    const checkRecoveryCode = () => {
        changeFormData({ type: "recoveryCode", value: confirmCode });
        dispatch(checkRecoveryCodeAsync({ name: formData.nameEmail, recoveryCode: confirmCode, changePageFn: changeFormData }));
    };

    const backToEmail = () => {
        dispatch(resetRecoveryEmail());
        changeFormData({ type: "page", value: "1" });
    };

    useEffect(() => {
        if (!isSendCodeAgain) {
            let timer = 60;
            const timeoutId: NodeJS.Timer = setInterval(() => {
                codeAgainBtn.current && (codeAgainBtn.current.innerText = timer + "");
                if (timer) {
                    timer--;
                } else {
                    clearTimeout(timeoutId);
                    setIsSendCodeAgain(true);
                }
            }, 1000);
        }
    }, [isSendCodeAgain]);
    return (
        <div className={styles.confirm_wrapper}>
            <p className={styles.title}>{t("signIn.confirm_email")}</p>
            <p className={styles.text}>
                {t("signIn.enter_verif_code")}
                {recoveryEmail}
            </p>
            <div className={styles.code_wrapper}>
                <VerificationInput
                    length={10}
                    autoFocus={true}
                    onChange={toggleConfirmCode}
                    value={confirmCode}
                    onFocus={() => dispatch(resetInvalidConfirmCodeRedux())}
                    classNames={{
                        container: styles.vf_container,
                        character: `${styles.vf_character} ${isConfirmCodeInvalid && styles.active}`,
                        characterInactive: styles.vf_character_inactive,
                        characterSelected: `${styles.vf_character_selected} ${isConfirmCodeInvalid && styles.active}`,
                    }}
                />
            </div>
            <div className={styles.btns_wrapper}>
                <button className={styles.back} onClick={backToEmail}>
                    {t("sundry.back")}
                </button>
                <button className={styles.create_acc} onClick={checkRecoveryCode} disabled={confirmCode.length < 10}>
                    {t("sundry.confirm")}
                </button>
            </div>
            <button className={styles.send_again} onClick={sendRecoveryConfirmCodeAgain} disabled={!isSendCodeAgain}>
                {t("signUp.send_again")} {!isSendCodeAgain && <span ref={codeAgainBtn}></span>}
            </button>
        </div>
    );
};

export default RecoveryConfirmation;
