import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { motion } from "framer-motion";
import { coming_from_top_anime, scale_anime } from "../../../../assets/animations";
import SectionTitle from "../../../../helperComponents/SectionTitile";
import CollapseRightIcon from "../../../../helperComponents/CollapseRightIcon";
import styles from "./faq.module.scss";

const Faq = () => {
    const { t } = useTranslation();

    const collapseData = useMemo(
        () => [
            {
                key: "1",
                label: t("faq.what_is_antidetect"),
                children: t("faq.what_is_antidetect_text"),
            },
            {
                key: "2",
                label: t("faq.what_is_digital"),
                children: t("faq.what_is_digital_text"),
            },
            {
                key: "3",
                label: t("faq.which_version"),
                children: t("faq.which_version_text"),
            },
            {
                key: "4",
                label: t("faq.how_to_create"),
                children: t("faq.how_to_create_text"),
            },
            {
                key: "5",
                label: t("faq.how_start_using"),
                children: t("faq.how_start_using_text"),
            },
        ],
        [t]
    );
    return (
        <div className={styles.faq_wrapper}>
            <div className={styles.faq_main}>
                <div className={styles.info_wrapper}>
                    <SectionTitle text={t("faq.title")} />
                    <ul className={styles.text_btn}>
                        <motion.p
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={coming_from_top_anime}
                            custom={-100}
                            className={styles.text}
                        >
                            {t("faq.text")}
                        </motion.p>
                        <motion.a
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={scale_anime}
                            href={process.env.REACT_APP_ASSISTANT_LINK}
                            target="_blank"
                            className={styles.assistant}
                        >
                            {t("faq.assistant")}
                        </motion.a>
                    </ul>
                </div>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={scale_anime}
                    className={styles.collapse_wrapper}
                >
                    <CollapseRightIcon list={collapseData} />
                </motion.div>
            </div>
        </div>
    );
};

export default Faq;
