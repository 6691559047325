import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { PaymetnMethodsDataType } from "../../../../types";
import { motion } from "framer-motion";
import { useAppDispatch } from "../../../../store";
import { constants } from "../../../../assets/constants";
import { setModalTypeRedux } from "../../../../store/features/modalSlice";
import { comeing_anime, items_op_scale_animation, opacity_scale_anime, scale_anime } from "../../../../assets/animations";
import SectionTitle from "../../../../helperComponents/SectionTitile";
import avax from "../../../../assets/images/paymentsMethods/AVAX.png";
import bch from "../../../../assets/images/paymentsMethods/BCH.png";
import bnb from "../../../../assets/images/paymentsMethods/BNB.png";
import btc from "../../../../assets/images/paymentsMethods/BTC.png";
import busd from "../../../../assets/images/paymentsMethods/BUSD.png";
import cgpt from "../../../../assets/images/paymentsMethods/CGPT.png";
import dai from "../../../../assets/images/paymentsMethods/DAI.png";
import dash from "../../../../assets/images/paymentsMethods/DASH.png";
import doge from "../../../../assets/images/paymentsMethods/DOGE.png";
import eth from "../../../../assets/images/paymentsMethods/ETH.png";
import ltc from "../../../../assets/images/paymentsMethods/LTC.png";
import matic from "../../../../assets/images/paymentsMethods/MATIC.png";
import sol from "../../../../assets/images/paymentsMethods/SOL.png";
import ton from "../../../../assets/images/paymentsMethods/TON.png";
import trx from "../../../../assets/images/paymentsMethods/TRX.png";
import usdc from "../../../../assets/images/paymentsMethods/USDC.png";
import usdt from "../../../../assets/images/paymentsMethods/USDT.png";
import verse from "../../../../assets/images/paymentsMethods/VERSE.png";
import xmr from "../../../../assets/images/paymentsMethods/XMR.png";
import Arrow from "../../../../assets/images/svg/arrow";
import styles from "./payment_methods.module.scss";

const PaymentMethods = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { modalTypes } = constants;

    const paymentMethodsData = useMemo<PaymetnMethodsDataType[]>(
        () => [
            { id: "1", icon: avax },
            { id: "2", icon: bch },
            { id: "3", icon: bnb },
            { id: "4", icon: btc },
            { id: "5", icon: busd },
            { id: "6", icon: cgpt },
            { id: "7", icon: dai },
            { id: "8", icon: dash },
            { id: "9", icon: doge },
            { id: "10", icon: eth },
            { id: "11", icon: ltc },
            { id: "12", icon: matic },
            { id: "13", icon: sol },
            { id: "14", icon: ton },
            { id: "15", icon: trx },
            { id: "16", icon: usdc },
            { id: "17", icon: usdt },
            { id: "18", icon: verse },
            { id: "19", icon: xmr },
        ],
        []
    );

    const [startIndex, setStartIndex] = useState(0);
    const [showItemsCount, setShowItemsCount] = useState<number>(window.innerWidth <= 1170 ? Math.floor((window.innerWidth - 160) / 80) : 12);

    const visibleItems = paymentMethodsData.slice(startIndex, startIndex + showItemsCount);

    const changeStartIndex = (type: "increase" | "decrease") => {
        if (type === "increase") {
            if (startIndex + showItemsCount < paymentMethodsData.length) {
                setStartIndex(startIndex + showItemsCount);
            }
        } else {
            if (startIndex - showItemsCount > 0) {
                setStartIndex(startIndex - showItemsCount);
            } else if (startIndex - showItemsCount <= 0) {
                setStartIndex(0);
            }
        }
    };

    const openModal = () => dispatch(setModalTypeRedux({ type: modalTypes.download }));

    useEffect(() => {
        const handleResize = () => setShowItemsCount(window.innerWidth <= 1200 ? Math.floor((window.innerWidth - 160) / 80) : 12);
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className={styles.payments_wrapper}>
            <div className={styles.payments_main}>
                <ul className={styles.info_wrapper}>
                    <SectionTitle text={t("payments_method.title")} />
                    <motion.p
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_scale_anime}
                        custom={0.5}
                        className={styles.text}
                    >
                        {t("payments_method.text")}
                    </motion.p>
                </ul>
                <div className={styles.payments_methods_wrapper}>
                    {!!startIndex && (
                        <button onClick={() => changeStartIndex("decrease")} className={styles.previous}>
                            <Arrow />
                        </button>
                    )}
                    {visibleItems.map((item, index) => (
                        <motion.div
                            key={item.id}
                            className={styles.method}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={items_op_scale_animation}
                            custom={index + 1}
                        >
                            <img src={item.icon} alt={`icon-${item.id}`} />
                        </motion.div>
                    ))}
                    {startIndex + showItemsCount < paymentMethodsData.length && (
                        <button onClick={() => changeStartIndex("increase")}>
                            <Arrow />
                        </button>
                    )}
                </div>
                <div className={styles.download_wrapper}>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={comeing_anime}
                        custom={-650}
                        className={styles.line}
                    ></motion.div>
                    <motion.button
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={scale_anime}
                        className={styles.download}
                        onClick={openModal}
                    >
                        {t("payments_method.download")}
                    </motion.button>
                </div>
            </div>
        </div>
    );
};

export default PaymentMethods;
