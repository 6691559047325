import { SectionTitleWithBorderTypes } from "../../types";
import { motion } from "framer-motion";
import { coming_from_top_anime } from "../../assets/animations";
import styles from "./section_title_with_border.module.scss";

const SectionTitleWithBorder = (props: SectionTitleWithBorderTypes) => {
    const { title, borderedText } = props;
    return (
        <motion.h3
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={coming_from_top_anime}
            custom={200}
            className={styles.advantages_title}
        >
            {title} <span>{borderedText}</span>
        </motion.h3>
    );
};

export default SectionTitleWithBorder;
