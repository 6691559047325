import { useEffect, useMemo, useState } from "react";
import { SignInFormDataTypes, ChangeFormDataTypes } from "../../types";
import { useTranslation } from "react-i18next";
import MASQLogo from "../../helperComponents/MASQLogo";
import SectionSubtitle from "../../helperComponents/SectionSubtitle";
import SectionTitle from "../../helperComponents/SectionTitile";
import styles from "./sign_in.module.scss";
import LoginPassword from "./pages/LoginPassword";
import RecoveryEmail from "./pages/RecoveryEmail";
import RecoveryConfirmation from "./pages/RecoveryConfirmation";
import ChangePassword from "./pages/ChangePassword";
import TFASignIn from "./pages/TFASingIn";
import { useAppDispatch, useAppSelector } from "../../store";
import { resetSignInErrorRedux } from "../../store/features/accountSlice";
import { Link } from "react-router-dom";
import { constants } from "../../assets/constants";
import { helpers } from "../../helpers";
import Confirmation from "../SignUp/pages/Confirmation";

const signInInitialState: SignInFormDataTypes = {
    name: "",
    password: "",
    isSigned: false,
    tfaToken: "",
    page: "0",
    nameEmail: "",
    recoveryCode: "",
    passwordRecovery: "",
    confirmPassword: "",
};

const SignIn = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { requiredTfa, signInError, user } = useAppSelector((state) => state.account);
    const { paths } = constants;

    const [formData, setFormData] = useState(signInInitialState);

    const changeFormData = ({ type, value }: ChangeFormDataTypes) => {
        signInError && dispatch(resetSignInErrorRedux());
        if (type === "name" && !helpers.isOnlyCyrillic(value)) return;
        setFormData((prevFormData) => ({ ...prevFormData, [type]: value }));
    };

    const signinPages = useMemo(
        () => ({
            "0": <LoginPassword formData={formData} changeFormData={changeFormData} />,
            "1": <RecoveryEmail formData={formData} changeFormData={changeFormData} />,
            "2": <RecoveryConfirmation formData={formData} changeFormData={changeFormData} />,
            "3": <ChangePassword formData={formData} changeFormData={changeFormData} />,
            "4": <TFASignIn formData={formData} changeFormData={changeFormData} />,
            "5": <Confirmation email={user.email} changeFormData={changeFormData} pageValue="0" />,
        }),
        [formData]
    );

    const currentPage = signinPages[formData.page as keyof typeof signinPages];

    useEffect(() => {
        !+formData.page && setFormData(signInInitialState);
    }, [formData.page]);

    useEffect(() => {
        requiredTfa && setFormData({ ...formData, page: "4" });
    }, [requiredTfa]);

    useEffect(() => {
        if (!user.emailConfirmed && typeof user.emailConfirmed === "boolean") {
            setFormData({ ...formData, page: "5" });
        }
    }, [user.emailConfirmed]);

    return (
        <div className={styles.sign_in_wrapper}>
            <div className={styles.signIn_left}>
                <div className={styles.signIn_left_main}>
                    <Link to={paths.home}>
                        <MASQLogo isWhite={true} />
                    </Link>
                    <div className={styles.info_wrapper}>
                        <SectionSubtitle text={t("andtidetected.newEra")} />
                        <SectionTitle text={t("andtidetected.andtidetectedBrowser")} style={{ fontSize: "80px", color: "#FFF" }} />
                        <p className={styles.info}>{t("andtidetected.making_the_work")}</p>
                    </div>
                </div>
            </div>
            <div className={styles.signIn_right}>
                <div className={styles.sign_in_right_main}>{currentPage}</div>
            </div>
        </div>
    );
};

export default SignIn;
