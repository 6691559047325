import { useState } from "react";
import { HelperInputPropsTypes } from "../../types";
import { ReactComponent as Eye } from "../../assets/images/svg/inputSVG/eye.svg";
import { ReactComponent as SlashEye } from "../../assets/images/svg/inputSVG/eye-slash.svg";
import styles from "./input.module.scss";

const Input = (props: HelperInputPropsTypes) => {
    const {
        type,
        placeholder,
        onChange,
        value,
        label,
        disabled,
        name,
        isError,
        errorWithoutMessage,
        keyDow = () => {},
        onFocus = () => {},
        onlyErrorBorder,
    } = props;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <label className={styles.input_wrapper}>
            <span className={styles.label}>{label}</span>
            <input
                type={type === "password" && showPassword ? "text" : type}
                placeholder={placeholder}
                onChange={(e) => {
                    onChange({ type: name, value: e.target.value });
                }}
                value={value}
                className={`${styles.input} ${(isError || errorWithoutMessage) && styles.error}`}
                disabled={disabled}
                onKeyDown={(e) => keyDow(e)}
                onFocus={() => onFocus()}
            />
            {type === "password" && (
                <div className={styles.show_password} onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <SlashEye /> : <Eye />}
                </div>
            )}
            {isError && !onlyErrorBorder && <span className={styles.error_message}>{isError}</span>}
        </label>
    );
};

export default Input;
