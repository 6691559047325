import { HelperComponentsMainTypes } from "../../types";
import { motion } from "framer-motion";
import { scale_anime } from "../../assets/animations";
import styles from "./rounded_button.module.scss";

const RoundedButton = ({ text }: HelperComponentsMainTypes) => {
    return (
        <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={scale_anime} className={styles.button_wrapper}>
            <a href="#prices" type="button" className={styles.button}>
                {text}
            </a>
            <div className={styles.border}></div>
        </motion.div>
    );
};

export default RoundedButton;
