import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { coming_delay, coming_from_top_anime, scale_anime } from "../../../../assets/animations";
import GrainyGradient from "../../../../helperComponents/GrainyGradient";
import SectionTitle from "../../../../helperComponents/SectionTitile";
import styles from "./forget_about.module.scss";

const ForgetAbout = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.forget_about_wrapper}>
            <GrainyGradient />
            <div className={styles.forget_about_main}>
                <div className={styles.text_title_wrapper}>
                    <div className={styles.title_wrapper}>
                        <SectionTitle text={t("forgetAbout.title")} style={{ fontSize: "65px", fontFamily: "Geo-400" }} />
                    </div>
                    <motion.p
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={coming_from_top_anime}
                        custom={100}
                        className={styles.forget_text}
                    >
                        {t("forgetAbout.text")}
                    </motion.p>
                </div>
            </div>
            <div className={styles.images_wrapper}>
                <div className={styles.first_lane}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={0.6}></motion.div>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={0.8}></motion.div>
                </div>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={scale_anime}
                    className={styles.second_line}
                ></motion.div>
                <div className={styles.third_line}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={1}></motion.div>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={0.4}></motion.div>
                </div>
            </div>
        </div>
    );
};

export default ForgetAbout;
