import { HelperComponentsMainTypes } from "../../types";
import { motion } from "framer-motion";
import { comeing_anime } from "../../assets/animations";
import styles from "./section_title.module.scss";

const SectionTitle = ({ text, style }: HelperComponentsMainTypes) => {
    return (
        <motion.h3
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={comeing_anime}
            custom={-100}
            style={style}
            className={styles.section_title}
        >
            {text}
        </motion.h3>
    );
};

export default SectionTitle;
