import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { loginAsync, resetTfaCodeData, resetTfaConfirmCode } from "../../../../store/features/accountSlice";
import { SignInPagesProps } from "../../../../types";
import styles from "./tfa_sign_in.module.scss";
import VerificationInput from "react-verification-input";

const TFASignIn = ({ formData, changeFormData }: SignInPagesProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { isTfaConfirmCodeInvalid } = useAppSelector((state) => state.account);

    const [tfaToken, setTfaToken] = useState<string>("");

    const toggleConfirmCode = (value: string) => {
        dispatch(resetTfaConfirmCode());
        (new RegExp("[0-9]").test(value) || !value) && setTfaToken(value);
    };

    const handleLoginFromTfa = () => dispatch(loginAsync({ formData: { ...formData, tfaToken } }));
    const backToSignIn = () => {
        changeFormData({ type: "page", page: "0" });
        dispatch(resetTfaCodeData());
    };
    const resetInvalidTfa = () => dispatch(resetTfaConfirmCode());
    return (
        <>
            <p className={styles.title}>{t("signUp.require_confirm")}</p>
            <p className={styles.subTitle}>{t("signIn.enter_tfa_digits")}</p>
            <div className={styles.verif_inp}>
                <VerificationInput
                    length={6}
                    autoFocus={true}
                    onChange={toggleConfirmCode}
                    value={tfaToken}
                    onFocus={resetInvalidTfa}
                    classNames={{
                        container: styles.vf_container,
                        character: `${styles.vf_character} ${isTfaConfirmCodeInvalid && styles.active}`,
                        characterInactive: styles.vf_character_inactive,
                        characterSelected: `${styles.vf_character_selected} ${isTfaConfirmCodeInvalid && styles.active}`,
                    }}
                />
            </div>
            <div className={styles.btns_wrapper}>
                <button className={styles.back} onClick={backToSignIn}>
                    {t("sundry.back")}
                </button>
                <button className={styles.confirm} onClick={handleLoginFromTfa} disabled={!(tfaToken.length === 6)}>
                    {t("sundry.confirm")}
                </button>
            </div>
        </>
    );
};

export default TFASignIn;
