import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { coming_from_top_anime, scale_anime } from "../../../../assets/animations";
import CollapseStars from "../../../../helperComponents/CollapseStars";
import SectionSubtitle from "../../../../helperComponents/SectionSubtitle";
import SectionTitle from "../../../../helperComponents/SectionTitile";
import styles from "./solv_problems.module.scss";

const SolveProblems = () => {
    const { t } = useTranslation();

    const collapseData = useMemo(
        () => [
            {
                key: "1",
                label: t("solveProblem.traffic"),
                children: t("solveProblem.traffic_text"),
            },
            {
                key: "2",
                label: t("solveProblem.affiliate"),
                children: t("solveProblem.affiliate_text"),
            },
            {
                key: "3",
                label: t("solveProblem.e_commerce"),
                children: t("solveProblem.e_commerce_text"),
            },
            {
                key: "4",
                label: t("solveProblem.testing"),
                children: t("solveProblem.testing_text"),
            },
            {
                key: "5",
                label: t("solveProblem.anonymity"),
                children: t("solveProblem.anonymity_text"),
            },
            {
                key: "6",
                label: t("solveProblem.bonushunting"),
                children: t("solveProblem.bonushunting_text"),
            },
        ],
        [t]
    );

    const [activityKey, setActivityKey] = useState<number>(0);
    const elRef = useRef<HTMLDivElement | null>(null);

    const scroll = () => {
        if (window.scrollY > 1400 && window.scrollY < 2600) {
            const num = Math.floor((window.scrollY - 1400) / 200) + 1;
            if (activityKey !== num) setActivityKey(num);
            if (num < 1) {
                setActivityKey(1);
            } else if (num > 6) {
                setActivityKey(6);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scroll);
    }, []);

    return (
        <div className={styles.solve_problem_main_wrapper}>
            <div className={styles.solve_problem_wrapper}>
                <div className={styles.texts_wrapper}>
                    <SectionSubtitle text={t("solveProblem.title")} style={{ fontSize: "62px", color: "#292929" }} />
                    <motion.p
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={coming_from_top_anime}
                        custom={-40}
                        className={styles.text}
                    >
                        {t("solveProblem.text")}
                    </motion.p>
                </div>
                <div className={styles.collapse_wrapper} ref={elRef}>
                    <CollapseStars list={collapseData} activityKey={activityKey} />
                </div>
            </div>
        </div>
    );
};

export default SolveProblems;
