import styles from "./masq_logo.module.scss";

const MASQLogo = ({ isWhite }: { isWhite: boolean }) => {
    return (
        <div className={styles.masq_logo_wrapper}>
            <div className={styles.icon_wrapper} style={{ background: isWhite ? "#fff" : "#14161F" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none">
                    <path
                        fill={isWhite ? "#24262F" : "#fff"}
                        d="M3.56 5.97a.758.758 0 0 0 1.48.327l.877-3.89c.035-.153.198-.21.265-.228.136-.034.329-.024.46.106l2.27 2.278c.745.748 2.203.748 2.948 0l2.27-2.278c.131-.132.324-.141.46-.106.068.017.23.074.264.228l.874 3.873a.78.78 0 1 0 1.521-.344l-.868-3.824c-.143-.636-.67-1.144-1.375-1.324-.74-.19-1.514.01-2.023.52l-2.27 2.279c-.156.156-.5.155-.654 0l-2.27-2.279C7.28.798 6.505.6 5.765.788c-.706.18-1.233.688-1.376 1.324L3.56 5.97ZM19.57 14.029a3.862 3.862 0 0 0-2.065-1.642 3.994 3.994 0 0 0-2.67.04c-.856.318-1.57.92-2.013 1.7a4.809 4.809 0 0 0-2.42-.648c-.852 0-1.688.226-2.419.652v-.003a3.885 3.885 0 0 0-2.987-1.927A3.934 3.934 0 0 0 1.66 13.48a3.674 3.674 0 0 0-.823 3.386 3.801 3.801 0 0 0 2.394 2.59 3.97 3.97 0 0 0 3.559-.428 3.725 3.725 0 0 0 1.67-3.079 2.75 2.75 0 0 0-.052-.573 3.386 3.386 0 0 1 1.993-.646c.72 0 1.418.227 1.993.647-.037.189-.055.38-.053.572a3.723 3.723 0 0 0 1.141 2.661 3.951 3.951 0 0 0 2.742 1.107c.6 0 1.182-.148 1.713-.401l.367.334a.705.705 0 0 0 .948-1.043l-.154-.14c.493-.53.831-1.18.956-1.89a3.676 3.676 0 0 0-.486-2.547l.001-.001ZM4.577 18.46c-1.43 0-2.59-1.125-2.59-2.512s1.16-2.513 2.59-2.513 2.59 1.125 2.59 2.513-1.16 2.512-2.59 2.512Zm11.65 0c-1.43 0-2.59-1.125-2.59-2.512s1.16-2.513 2.59-2.513 2.589 1.125 2.589 2.513-1.16 2.512-2.59 2.512Z"
                    />
                    <path
                        fill={isWhite ? "#24262F" : "#fff"}
                        fillRule="evenodd"
                        d="M20.11 9.532a.661.661 0 0 1-.662.661H1.378a.661.661 0 0 1 0-1.322h18.07c.365 0 .661.296.661.661Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width={55} height={16} fill="none">
                <path
                    fill={isWhite ? "#fff" : "#24262F"}
                    d="m20.426.28-5.337 14.992h2.025l1.165-3.362h6.44l1.166 3.362h2.025L22.573.28h-2.147Zm-1.492 9.766 2.555-7.389 2.575 7.39h-5.132.002ZM11.245.28h2.147v14.992H11.51V3.557l-4.682 8.16h-.246L1.901 3.578v11.694H0V.28h2.147l4.558 7.924L11.244.28h.002Zm27.448 10.077c.062.278.092.575.092.889 0 1.314-.456 2.36-1.37 3.138-.914.778-2.105 1.166-3.578 1.166-1.336 0-2.478-.328-3.425-.985-.947-.657-1.612-1.557-1.993-2.698l1.636-.986c.56 1.814 1.833 2.72 3.824 2.72.982 0 1.731-.21 2.25-.632.517-.422.776-.982.776-1.681 0-.7-.26-1.25-.777-1.606-.518-.357-1.383-.736-2.596-1.135-.6-.2-1.057-.36-1.37-.482a11.255 11.255 0 0 1-1.176-.557c-.47-.25-.82-.507-1.053-.772-.231-.263-.439-.61-.624-1.038a3.658 3.658 0 0 1-.276-1.457c0-1.3.436-2.331 1.309-3.095C31.215.383 32.279 0 33.533 0c1.131 0 2.116.3 2.954.9a5.548 5.548 0 0 1 1.892 2.377l-1.596.964c-.613-1.542-1.696-2.313-3.25-2.313-.776 0-1.404.2-1.88.6-.477.399-.716.942-.716 1.627s.224 1.156.674 1.5c.45.342 1.227.7 2.33 1.07.395.128.675.222.839.279.163.056.42.157.766.3.348.143.604.257.767.342.163.086.385.207.665.364s.487.307.624.45c.136.142.296.318.48.524.184.207.317.418.4.632.081.214.153.46.214.738l-.002.003ZM55 7.777c0-2.156-.726-3.991-2.178-5.505-1.451-1.513-3.2-2.27-5.244-2.27-2.045 0-3.79.757-5.234 2.27-1.445 1.514-2.167 3.349-2.167 5.504 0 2.156.722 3.99 2.167 5.504 1.444 1.514 3.19 2.27 5.234 2.27 1.458 0 2.807-.421 4.048-1.264L53.242 16l1.308-1.243-1.574-1.649C54.326 11.608 55 9.831 55 7.775v.001Zm-4.724 5.096c-.012.009-.026.015-.04.023l-.012.007a5.125 5.125 0 0 1-2.134.674c-.066.006-.132.012-.198.016a5.73 5.73 0 0 1-.314.01c-1.367 0-2.548-.445-3.544-1.332a5.937 5.937 0 0 1-2.029-4.504c0-1.813.79-3.434 2.03-4.505.996-.887 2.176-1.33 3.543-1.33 1.366 0 2.544.443 3.54 1.33 1.24 1.07 2.03 2.69 2.03 4.505 0 2.198-1.16 4.11-2.873 5.107v-.002Z"
                />
            </svg>
        </div>
    );
};

export default MASQLogo;
