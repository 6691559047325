import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./back_to_some_page.module.scss";
import Arrow from "../../assets/images/svg/arrow";
import { ArrowLeftOutlined } from "@ant-design/icons";

const BackToSomePage = ({ page, path }: { page: string; path: string }) => {
    const { t } = useTranslation();

    return (
        <Link to={path} className={styles.back_btn}>
            <ArrowLeftOutlined />
            <span>
                {t(`sundry.back_to`)} {page}
            </span>
        </Link>
    );
};

export default BackToSomePage;
