const FacebookIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={74} height={74} fill="none">
        <rect width={73} height={73} x={0.5} y={0.5} fill="#fff" rx={36.5} />
        <rect width={73} height={73} x={0.5} y={0.5} stroke="#EEE" rx={36.5} />
        <path
            fill="#14161F"
            d="m44.107 39.051.873-6.05h-5.46v-3.925c0-1.655.76-3.27 3.206-3.27h2.484v-5.151s-2.253-.41-4.407-.41c-4.499 0-7.437 2.9-7.437 8.144v4.612h-4.999v6.05h5v14.627c1.003.168 2.03.254 3.076.254 1.046 0 2.073-.086 3.076-.254V39.051h4.588Z"
        />
    </svg>
);
export default FacebookIcon;
