import Header from "../Header";
import Advantages from "./components/Advantages";
import AntdtidetectedBrowser from "./components/AndtidetectedBrowser";
import AppPhotos from "./components/AppPhotos";
import EachProfile from "./components/EachProfile";
import Faq from "./components/Faq";
import Fingerprints from "./components/Fingerprints";
import Footer from "./components/Footer";
import ForgetAbout from "./components/ForgetAbout";
import HowItWorks from "./components/HowItWroks";
import Partners from "./components/Partners";
import PaymentMethods from "./components/PaymentMethods";
import SolveProblems from "./components/SolveProblems";
import Subscription from "./components/Subscription";

const MainPage = () => {
    return (
        <div>
            <Header />
            <AntdtidetectedBrowser />
            <AppPhotos />
            <SolveProblems />
            <ForgetAbout />
            <Advantages />
            <Fingerprints />
            <EachProfile />
            <HowItWorks />
            {/* <Partners /> */}
            <Subscription />
            <PaymentMethods />
            <Faq />
            <Footer />
        </div>
    );
};

export default MainPage;
