import { constants } from "../assets/constants";
import { Routes, Route, useLocation } from "react-router-dom";
import { RouteType } from "../types";
import { useAppSelector } from "../store";
import TariffPlan from "../components/PrivatePages/TariffPlan";
import MainPage from "../components/MainPage";
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";
import Account from "../components/PrivatePages/Account";
import PrivatePages from "../components/PrivatePages";
import TermsAndPrivacy from "../helperComponents/TermsAndPrivacy";

const { paths } = constants;

const publicRoutes: RouteType[] = [
    { id: "MainPage", path: paths.home, element: <MainPage /> },
    { id: "SignIn", path: paths.signIn, element: <SignIn /> },
    { id: "SignUp", path: paths.signUp, element: <SignUp /> },
    { id: "TermsAndPrivacy", path: paths.terms_and_privacy, element: <TermsAndPrivacy /> },
    { id: "MainPage", path: "*", element: <MainPage /> },
];

const privateRoutes: RouteType[] = [
    { id: "MainPage", path: paths.home, element: <MainPage /> },
    { id: "Account", path: paths.account, element: <Account />, index: true },
    { id: "TermsAndPrivacy", path: paths.terms_and_privacy, element: <TermsAndPrivacy /> },
    { id: "Tariff", path: paths.tariff, element: <TariffPlan /> },
    { id: "Tariff", path: "*", element: <Account /> },
];

const Router = () => {
    const emailConfirmed = useAppSelector((state) => state.account.user.emailConfirmed);
    return (
        <>
            {emailConfirmed ? (
                <PrivatePages>
                    <Routes>
                        {privateRoutes.map(({ id, path, element, index }) => (
                            <Route path={path} element={element} key={id} index={!!index} />
                        ))}
                    </Routes>
                </PrivatePages>
            ) : (
                <Routes>
                    {publicRoutes.map(({ id, path, element, index }) => (
                        <Route path={path} element={element} key={id} index={!!index} />
                    ))}
                </Routes>
            )}
        </>
    );
};

export default Router;
