import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CheckNameFreeTypes, NameTypes, RegistrationAsyncTypes, SignUpInitialStateTypes } from "../../types";
import { constants } from "../../assets/constants";
import { t } from "i18next";
import { helpers } from "../../helpers";
import { changeConfirmCodeResendTimeoutRedux, changeUserDataAfterRegistrationRedux } from "./accountSlice";

const { endpoints, paths } = constants;
const hash = helpers.hash();

export const checkNameFreeAsync = createAsyncThunk("signUp/checkNameFreeAsync", async ({ name, email }: CheckNameFreeTypes) => {
    let nameResponse;
    if (name) {
        nameResponse = await fetch(process.env.REACT_APP_API_URL + endpoints.checkNameFree + name, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
        });
    }
    let emailResponse;
    if (email) {
        emailResponse = await fetch(process.env.REACT_APP_API_URL + endpoints.checkNameFree + email, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
        });
    }

    const nameData = name ? await nameResponse?.json() : { isFree: true };
    const emailData = email ? await emailResponse?.json() : { isFree: true };
    if (nameData.message || emailData.message) throw new Error(nameData.message || emailData.message);
    return {
        nameError: nameData.isFree ? "" : t("signUp.name_error"),
        emailError: emailData.isFree ? "" : t("signUp.email_error"),
    };
});

export const sendConfirmCodeAsync = createAsyncThunk("signUp/sendConfirmCodeAsync", async ({ name }: NameTypes, { dispatch }) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoints.sendConfirmCode, {
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            os: `${window.navigator.platform} ${window.navigator.userAgent}`,
            cpu: window.navigator.appVersion,
            app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
            hash,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
            name,
        }),
    });
    const data = await response.json();
    if (data.message) throw new Error(data.message);
    dispatch(changeConfirmCodeResendTimeoutRedux(data.confirmCodeResendTimeout));
    return data;
});

export const registrationAsync = createAsyncThunk(
    "signUp/registrationAsync",
    async ({ formData: { name, email, password }, changePageFn }: RegistrationAsyncTypes, { dispatch }) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.registration, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                name,
                email,
                password,
            }),
        });
        const data = await response.json();
        if (data.message) throw new Error(data.message);
        changePageFn?.();
        dispatch(changeUserDataAfterRegistrationRedux(data));
        return data;
    }
);

const initialState: SignUpInitialStateTypes = {
    signUpLoading: false,
    isFree: false,
    nameError: "",
    emailError: "",
    confirmCodeSent: false,
};

const signUpSlice = createSlice({
    name: "signUp",
    initialState,
    reducers: {
        resetFormErrorsRedux(state, action) {
            switch (action.payload.type) {
                case "all":
                    state.nameError = "";
                    state.emailError = "";
                    break;
                case "name":
                    state.nameError = "";
                    break;
                case "email":
                    state.emailError = "";
                    break;
            }
        },
        resetSignUpStateToInitialRedux(state) {
            state.signUpLoading = initialState.signUpLoading;
            state.isFree = initialState.isFree;
            state.nameError = initialState.nameError;
            state.emailError = initialState.emailError;
            state.confirmCodeSent = initialState.confirmCodeSent;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registrationAsync.pending, (state) => {
                state.signUpLoading = true;
            })
            .addCase(registrationAsync.fulfilled, (state, action) => {
                state.signUpLoading = false;
                state.confirmCodeSent = action.payload;
            })
            .addCase(registrationAsync.rejected, (state, action) => {
                state.signUpLoading = false;
            })
            .addCase(checkNameFreeAsync.fulfilled, (state, action) => {
                state.nameError = action.payload.nameError;
                state.emailError = action.payload.emailError;
            })
            .addCase(sendConfirmCodeAsync.pending, (state) => {
                state.signUpLoading = true;
            })
            .addCase(sendConfirmCodeAsync.fulfilled, (state, action) => {
                state.signUpLoading = false;
            })
            .addCase(sendConfirmCodeAsync.rejected, (state) => {
                state.signUpLoading = false;
            });
    },
});
export const { resetFormErrorsRedux, resetSignUpStateToInitialRedux } = signUpSlice.actions;
export default signUpSlice.reducer;
