import { useTranslation } from "react-i18next";
import { SignInPageProps } from "../../../../types";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { loginAsync } from "../../../../store/features/accountSlice";
import { constants } from "../../../../assets/constants";
import Input from "../../../../helperComponents/Input";
import styles from "./login_password.module.scss";

const LoginPassword = ({ formData, changeFormData }: SignInPageProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { signInError } = useAppSelector((state) => state.account);
    const { paths } = constants;
    const isPasswordDisabled = !formData.name;

    const signIn = () => {
        dispatch(loginAsync({ formData }));
    };

    const keyListener = (e: KeyboardEvent) => e.key === "Enter" && signIn();
    return (
        <>
            <p className={styles.title}>{t("sundry.signIn")}</p>
            <p className={styles.signInError}>{signInError}</p>
            <div className={styles.inputs_wrapper}>
                <Input
                    label={t("signIn.your_username")}
                    type="text"
                    placeholder={t("signIn.username_placeholder")}
                    value={formData.name}
                    onChange={changeFormData}
                    name="name"
                    keyDow={keyListener}
                    isError={signInError}
                    onlyErrorBorder={true}
                />
                <Input
                    label={t("sundry.password")}
                    type="password"
                    placeholder={t("signIn.password_placeholder")}
                    value={formData.password}
                    onChange={changeFormData}
                    name="password"
                    isError={signInError}
                    disabled={isPasswordDisabled}
                    onlyErrorBorder={true}
                />
                <div className={styles.stay_signed_wrapper}>
                    <label className={styles.checkbox}>
                        <input
                            type="checkbox"
                            checked={formData.isSigned}
                            onChange={(e) => changeFormData({ type: "isSigned", value: e.target.checked })}
                        />
                        {t("signIn.stay_signed")}
                    </label>
                    <button className={styles.forget} onClick={() => changeFormData({ type: "page", value: "1" })}>
                        {t("signIn.forget_password")}
                    </button>
                </div>
            </div>
            <button className={styles.sign_in_btn} onClick={signIn}>
                {t("sundry.signIn")}
            </button>
            <div className={styles.dont_have}>
                <span>{t("signIn.dont_have_acc")}</span>
                <Link to={paths.signUp}>{t("sundry.signUp")}</Link>
            </div>
        </>
    );
};

export default LoginPassword;
