import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TariffSliceInitialStateTypes } from "../../types";
import { constants } from "../../assets/constants";
import { helpers } from "../../helpers";

const hash: string = helpers.hash();

const { endpoints } = constants;

export const getTariffListAsync = createAsyncThunk("tariffList/getTariffListAsync", async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoints.tariffList, {
        method: "GET",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            os: `${window.navigator.platform} ${window.navigator.userAgent}`,
            cpu: window.navigator.appVersion,
            app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
            hash,
        },
    });
    const data = await response.json();
    if (data.message) throw new Error(data.message);
    return data;
});

const initialState: TariffSliceInitialStateTypes = {
    tariffLoading: false,
    tariffList: [],
};

const tariffList = createSlice({
    name: "tariffList",
    initialState,
    reducers: {
        resetTariffToInitialRedux: (state) => {
            state.tariffLoading = initialState.tariffLoading;
            state.tariffList = initialState.tariffList;
        },
    },
    extraReducers: (build) => {
        build
            .addCase(getTariffListAsync.pending, (state) => {
                state.tariffLoading = true;
            })
            .addCase(getTariffListAsync.fulfilled, (state, action) => {
                state.tariffLoading = false;
                state.tariffList = action.payload;
            })
            .addCase(getTariffListAsync.rejected, (state) => {
                state.tariffLoading = false;
            });
    },
});

export const { resetTariffToInitialRedux } = tariffList.actions;
export default tariffList.reducer;
