import { GetTariffBuyConfigsTypes, TariffListTypes } from "../types";

const hash = (): string => {
    let hash = localStorage.getItem("hash") || "";
    if (!hash) {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 100; i++) hash += characters.charAt(Math.floor(Math.random() * characters.length));
        hash += "&FROM_LANDING";
        localStorage.setItem("hash", hash);
    }
    return hash;
};

function isPasswordContainSymbolOrNumber(pass: string): boolean {
    let firstRule = false;
    let secondRule = false;
    const password = pass.split("");
    for (let i = 0; i < password.length; i++) {
        const symbol = password[i];
        if (!firstRule && new RegExp("^[A-Za-z]+$").test(symbol)) firstRule = true;
        if (!secondRule && new RegExp("^[!@#$%^&*()-_+=;:,./\\`~{}]+$").test(symbol)) secondRule = true;
        if (firstRule && secondRule) return true;
    }
    return firstRule && secondRule;
}

function isOnlyCyrillic(str: string): boolean {
    const regexp = /^[a-zA-Z\d\s@.]*$/;
    return (regexp.test(str) || !str) && isNaN(+str[0]);
}

function getTariffBuyConfigs({
    isTeam,
    isLight,
    tariffList,
    discountedTariffList,
    isDiscount,
    annualOrMonthly,
}: GetTariffBuyConfigsTypes): TariffListTypes {
    let res = (isDiscount && discountedTariffList.length ? discountedTariffList : tariffList).find((tariff) => {
        if (isLight) {
            if (tariff.name.toLowerCase().includes("light")) {
                return true;
            }
        } else if (isTeam) {
            if (tariff.name.toLowerCase().includes("team")) {
                if (annualOrMonthly === "annual") {
                    if (tariff.name.toLowerCase().includes("yearly")) {
                        return true;
                    }
                } else if (annualOrMonthly === "monthly") {
                    if (!tariff.name.toLowerCase().includes("yearly")) {
                        return true;
                    }
                }
            }
        } else {
            if (tariff.name.toLowerCase().includes("pro")) {
                if (annualOrMonthly === "annual") {
                    if (tariff.name.toLowerCase().includes("yearly")) {
                        return true;
                    }
                } else if (annualOrMonthly === "monthly") {
                    if (!tariff.name.toLowerCase().includes("yearly")) {
                        return true;
                    }
                }
            }
        }
    });
    if (res === undefined) {
        if (isDiscount && discountedTariffList.length) {
            res = discountedTariffList[0];
        } else {
            res = tariffList[0];
        }
    }
    return res;
}

function patchSubscriptionName(t: Function, subName: string, withSubscription?: boolean | undefined): string {
    const sName = subName.toLowerCase().replace("yearly", "").trim();
    const res = t(`subscriptions.${sName}${withSubscription ? "_subscription" : ""}`);
    return res.includes(".") ? subName : res;
}

function correctTimeForUTC(milliseconds: number): string {
    const date = new Date(milliseconds);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${day}.${month}.${year}, ${hours}:${minutes} UTC`;
}

function getLanguage() {
    return localStorage.getItem("i18nextLng");
}

export const helpers = {
    hash,
    isPasswordContainSymbolOrNumber,
    isOnlyCyrillic,
    getTariffBuyConfigs,
    patchSubscriptionName,
    correctTimeForUTC,
    getLanguage,
};
