import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";
import { constants } from "../../../../assets/constants";
import { motion } from "framer-motion";
import { opacity_scale_anime } from "../../../../assets/animations";
import GrainyGradient from "../../../../helperComponents/GrainyGradient";
import SectionTitleWithBorder from "../../../../helperComponents/SectionTitleWithBorder";
import done_gradient from "../../../../assets/images/done_gradient.svg";
import done_orange from "../../../../assets/images/done_orange.svg";
import styles from "./subscription.module.scss";
import { useNavigate } from "react-router-dom";

const options = [
    { text: "5 Profiles", subs: ["free"] },
    { text: "Profiles", subs: ["light", "pro", "team"] },
    { text: "1 Folders", subs: ["free"] },
    { text: "2 Folders", subs: ["light"] },
    { text: "10 Folders", subs: ["pro", "team"] },
    { text: "Proxy", subs: ["free", "light", "pro", "team"] },
    { text: "Proxy Checker", subs: ["free", "light", "pro", "team"] },
    { text: "Mobile Profiles", subs: ["free", "light", "pro", "team"] },
    { text: "Cookies import", subs: ["free"] },
    { text: "Tags", subs: ["light", "pro", "team"] },
    { text: "Profiles & Cookies export", subs: ["light", "pro", "team"] },
    { text: "Profiles & Cookies import", subs: ["light", "pro", "team"] },
    { text: "Profiles & Folders sharing", subs: ["light", "pro", "team"] },
    { text: "Profile Presets", subs: ["pro", "team"] },
    { text: "Automations", subs: ["pro", "team"] },
    { text: "Team-Wide", subs: ["team"] },
];

const Subscription = () => {
    const { t } = useTranslation();
    const tariffList = useAppSelector((state) => state.tariff.tariffList);
    const emailConfirmed = useAppSelector((state) => state.account.user.emailConfirmed);
    const navigator = useNavigate();
    const { modalTypes, paths } = constants;

    const openPage = (name: string) => {
        navigator(emailConfirmed ? paths.tariff : paths.signUp);
        localStorage.setItem("try_now_value", name.toLowerCase());
    };

    const optionsItems: string[] = options.reduce((acc: string[], item) => {
        if (item.subs.includes("free")) {
            acc.push(item.text);
        }
        return acc;
    }, []);

    return (
        <div className={styles.subscription_wrapper} id="prices">
            <GrainyGradient />
            <div className={styles.subscription_main}>
                <SectionTitleWithBorder title={t("subscription.title")} borderedText={t("sundry.subscription")} />
                <div className={styles.subscription_plans_wrapper}>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={opacity_scale_anime}
                        custom={0}
                        className={styles.subscription}
                    >
                        <p className={styles.subscriptionName}>{t("subscription.free")}</p>
                        <p className={styles.subscriptionSubName}>
                            All the basic functions are available for free. A great option to try out the service
                        </p>
                        <p className={styles.priceAndDuration}>
                            <span className={styles.price}>For free</span>
                            <span className={styles.duration}>/ unlimited</span>
                        </p>
                        <p className={styles.getStartedBtn} onClick={() => openPage("free")}>
                            {t("sundry.get_started")}
                        </p>
                        <p className={styles.optionsTitle}>Free subscription includes:</p>
                        <div className={styles.optionsItemWrapper}>
                            {optionsItems.map((optionText) => {
                                return (
                                    <p>
                                        <img src={done_gradient} alt="done_gradient" />
                                        <span>{optionText}</span>
                                    </p>
                                );
                            })}
                        </div>
                    </motion.div>
                    {tariffList.map((subscription, i) => {
                        const { _id, name, price, userSeatPrice, profilesUnlimited } = subscription;
                        const delay = (i + 1) * 0.1;

                        const optionsItems: string[] = options.reduce((acc: string[], item) => {
                            if (item.subs.includes(name.toLowerCase())) {
                                acc.push(item.text);
                            }
                            return acc;
                        }, []);
                        const isPro = name?.toLowerCase().includes("pro");
                        const doneIcon = isPro ? done_orange : done_gradient;
                        return (
                            <motion.div
                                key={_id}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={opacity_scale_anime}
                                custom={delay}
                                className={`${styles.subscription} ${isPro && styles.proStyles}`}
                            >
                                <p className={styles.subscriptionName}>{name}</p>
                                <p className={styles.subscriptionSubName}>{t(`subscription.${name?.toLowerCase()}_subscription_sub_name`)}</p>
                                <p className={styles.priceAndDuration}>
                                    <span className={styles.price}>${price}</span>
                                    <span className={styles.duration}>/ {t("sundry.month")}</span>
                                </p>
                                {userSeatPrice && (
                                    <p className={styles.perAdditionalSeat}>
                                        <span className={styles.price}>${userSeatPrice}</span>
                                        <span className={styles.duration}>/ per additional seat</span>
                                    </p>
                                )}
                                <p className={styles.getStartedBtn} onClick={() => openPage(name)}>
                                    {t("sundry.get_started")}
                                </p>
                                <p className={styles.optionsTitle}>
                                    <span>{name}</span> <span>{t("subscription.subscription_includes")}</span>
                                </p>
                                <div className={styles.optionsItemWrapper}>
                                    {optionsItems.map((optionText) => {
                                        return (
                                            <p>
                                                <img src={doneIcon} alt="doneIcon" />
                                                <span>
                                                    {profilesUnlimited && optionText === "Profiles" && (
                                                        <span className={styles.unlimitedSymbol}>&#8734;</span>
                                                    )}
                                                    {optionText}
                                                </span>
                                            </p>
                                        );
                                    })}
                                </div>
                            </motion.div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Subscription;
