import { SignInPagesProps } from "../../../../types";
import { Link } from "react-router-dom";
import { constants } from "../../../../assets/constants";
import { useTranslation } from "react-i18next";
import { ReactComponent as OkIcon } from "../../../../assets/images/svg/accountSVG/okIcon.svg";
import { ReactComponent as ErrorIcon } from "../../../../assets/images/svg/accountSVG/closeIcon.svg";
import { helpers } from "../../../../helpers";
import { useAppDispatch } from "../../../../store";
import { recoveryPasswordAsync, resetByDefaultAccountRedux } from "../../../../store/features/accountSlice";
import Input from "../../../../helperComponents/Input";
import styles from "./change_password.module.scss";

const ChangePassword = ({ formData, changeFormData }: SignInPagesProps) => {
    const { t } = useTranslation();
    const { paths, passwordPattern } = constants;
    const dispatch = useAppDispatch();

    const isPasswordCorrect = formData.passwordRecovery.length > 7 && passwordPattern.test(formData.passwordRecovery);
    const arePasswordsSame = formData.passwordRecovery === formData.confirmPassword;

    const backToMainPage = () => {
        dispatch(resetByDefaultAccountRedux());
        changeFormData({ type: "page", value: "0" });
    };

    const changePassword = () => {
        const goToSignIn = () => backToMainPage();
        dispatch(
            recoveryPasswordAsync({
                name: formData.nameEmail,
                recoveryCode: formData.recoveryCode,
                password: formData.passwordRecovery,
                goToSignIn,
            })
        );
    };

    return (
        <>
            <p className={styles.title}>{t("account.change_password")}</p>
            <div className={styles.inputs_wrapper}>
                <Input
                    label={t("sundry.password")}
                    type="password"
                    placeholder={t("signUp.enter_password")}
                    value={formData.passwordRecovery}
                    onChange={changeFormData}
                    name="passwordRecovery"
                />
                <Input
                    label={t("signUp.confirm_password")}
                    type="password"
                    placeholder={t("signUp.confirm_password")}
                    value={formData.confirmPassword}
                    onChange={changeFormData}
                    name="confirmPassword"
                    disabled={!isPasswordCorrect}
                />
            </div>
            <div className={styles.btns_wrapper}>
                <button className={styles.back} onClick={backToMainPage}>
                    {t("sundry.back")}
                </button>
                <button className={styles.create_acc} onClick={changePassword} disabled={!arePasswordsSame}>
                    {t("sundry.change")}
                </button>
            </div>
            <div className={styles.have_acc}>
                <span>{t("signUp.have_an_acc")}</span>
                <Link to={paths.signIn}>{t("sundry.signIn")}</Link>
            </div>
        </>
    );
};

export default ChangePassword;
