import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useTranslation } from "react-i18next";
import { ChangeEmailProps } from "../../../../types";
import { constants } from "../../../../assets/constants";
import { checkNameFreeAsync, resetFormErrorsRedux } from "../../../../store/features/signupSlice";
import {
    changeEmailSendConfirmCodeAsync,
    confirmCurrentEmailAsync,
    confirmNewEmailAsync,
    resetByDefaultSettingsRedux,
    resetInvalidConfirmCodeRedux,
    resetPasswordStatusRedux,
    setNewEmailAsync,
} from "../../../../store/features/settingsSlice";
import { resetModalStateToInitialRedux } from "../../../../store/features/modalSlice";
import { showNotifyRedux } from "../../../../store/features/notifySlice";
import { changeEmailInitialState } from "../..";
import CheckedIcon from "../../../../assets/images/svg/accountSVG/ChechkedIcon";
import Input from "../../../Input";
import styles from "./change_email.module.scss";
import VerificationInput from "react-verification-input";

const ChangeEmail = ({ changeEmailFormData, setChangeEmailFormData, changeFormData }: ChangeEmailProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { token } = useAppSelector((state) => state.account);
    const { emailError } = useAppSelector((state) => state.signUp);
    const { currentEmailConfirmCodeSent, confirmCodeSent, passwordStatus, isConfirmCodeInvalid, isConfirmCodeValid } = useAppSelector(
        (state) => state.settings
    );

    const { emailPattern, notifyTypes } = constants;

    const isPasswordValid = changeEmailFormData.password.length > 2;
    const isNewEmailCorrect =
        !emailError && !changeEmailFormData.newEmailLocalError && emailPattern.test(changeEmailFormData.newEmail) && changeEmailFormData.newEmail.length > 0;

    const confirmPassword = () => {
        if (isPasswordValid) {
            setChangeEmailFormData({ ...changeEmailFormData, isPasswordConfirmed: true });
            dispatch(resetPasswordStatusRedux());
        }
    };

    const sendConfirmCode = () => dispatch(changeEmailSendConfirmCodeAsync({ token }));

    const checkConfirmCode = () => {
        dispatch(confirmCurrentEmailAsync({ token, currentEmailConfirmCode: changeEmailFormData.confirmActualEmail }));
    };

    const toggleConfirmCode = (value: string) => {
        dispatch(resetInvalidConfirmCodeRedux());
        setChangeEmailFormData({ ...changeEmailFormData, confirmActualEmail: value.toUpperCase() });
    };

    const toggleNewEmailConfirmCode = (value: string) => {
        dispatch(resetInvalidConfirmCodeRedux());
        setChangeEmailFormData({ ...changeEmailFormData, confirmNewEmail: value });
    };

    const changeEmail = () => dispatch(setNewEmailAsync({ token, email: changeEmailFormData.newEmail, password: changeEmailFormData.password }));

    const confirmNewEmailConfirmationCode = () =>
        dispatch(
            confirmNewEmailAsync({
                token,
                email: changeEmailFormData.newEmail,
                newEmailConfirmCode: changeEmailFormData.confirmNewEmail.trim().toUpperCase(),
                fn: emailChanged,
            })
        );

    const emailChanged = () => {
        setChangeEmailFormData(changeEmailInitialState);
        dispatch(showNotifyRedux({ type: notifyTypes.success, title: t("change_email.have_a_new_email") }));
        dispatch(resetModalStateToInitialRedux());
        dispatch(resetByDefaultSettingsRedux());
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (emailPattern.test(changeEmailFormData.newEmail)) {
            timeout = setTimeout(() => {
                dispatch(checkNameFreeAsync({ name: changeEmailFormData.newEmail, email: changeEmailFormData.newEmail }));
            }, 300);
        }
        return () => clearTimeout(timeout);
    }, [changeEmailFormData.newEmail]);

    useEffect(() => {
        return () => {
            dispatch(resetFormErrorsRedux({ type: "all" }));
        };
    }, []);
    return (
        <>
            {(!changeEmailFormData.isPasswordConfirmed && !currentEmailConfirmCodeSent) || passwordStatus ? (
                <div className={styles.change_step}>
                    <Input
                        name="password"
                        label={t("signUp.confirm_password")}
                        placeholder={t("signUp.confirm_password")}
                        value={changeEmailFormData.password}
                        onChange={changeFormData}
                        type="password"
                        isError={passwordStatus}
                    />
                    <button className={styles.text_btn} onClick={confirmPassword} disabled={!isPasswordValid}>
                        {t("sundry.confirm")}
                    </button>
                </div>
            ) : (
                <div className={styles.info_step}>
                    <h2 className={`${styles.disabled_part_header} ${styles.confirmed}`}>
                        <span>{t("signUp.confirm_password")}</span>
                    </h2>
                    <CheckedIcon />
                </div>
            )}
            <div className={styles.change_step}>
                {changeEmailFormData.isPasswordConfirmed && !currentEmailConfirmCodeSent ? (
                    <>
                        <div className={styles.send_code_part_text_place}>
                            <p className={styles.send_code_title}>{t("change_email.email_changing")}</p>
                            <p className={styles.send_code_subtitle}>{t("change_email.email_changing_text")}</p>
                        </div>
                        <button className={styles.text_btn} onClick={sendConfirmCode}>
                            {t("sundry.send_code")}
                        </button>
                    </>
                ) : (
                    <div className={styles.info_step}>
                        <h2 className={`${styles.disabled_part_header} ${currentEmailConfirmCodeSent && styles.confirmed}`}>
                            <span>{t("change_email.email_changing")}</span>
                        </h2>
                        {currentEmailConfirmCodeSent && <CheckedIcon />}
                    </div>
                )}
            </div>
            <div className={styles.change_step}>
                {currentEmailConfirmCodeSent && !isConfirmCodeValid ? (
                    <div className={styles.confirm_code_part}>
                        <div className={styles.confirm_code_text_place}>
                            <p className={styles.send_code_title}>{t("change_email.confirm_you_have_actual_email")}</p>
                            <p className={styles.send_code_subtitle}>{t("change_email.we_sent_verif_code")}</p>
                        </div>

                        <div className={styles.confirm_code_input_place} onClick={() => dispatch(resetInvalidConfirmCodeRedux())}>
                            <VerificationInput
                                length={10}
                                autoFocus={true}
                                onChange={toggleConfirmCode}
                                value={changeEmailFormData.confirmActualEmail}
                                classNames={{
                                    container: styles.vf_container,
                                    character: `${styles.vf_character} ${isConfirmCodeInvalid && styles.active}`,
                                    characterInactive: styles.vf_character_inactive,
                                    characterSelected: `${styles.vf_character_selected} ${isConfirmCodeInvalid && styles.active}`,
                                }}
                            />
                            <button className={styles.text_btn} onClick={checkConfirmCode}>
                                {t("sundry.confirm")}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.info_step}>
                        <h2 className={`${styles.disabled_part_header} ${isConfirmCodeValid && styles.confirmed}`}>
                            <span>{t("change_email.confirm_actual_email")}</span>
                        </h2>
                        {isConfirmCodeValid && <CheckedIcon />}
                    </div>
                )}
            </div>
            <div className={styles.change_step}>
                {isConfirmCodeValid && !confirmCodeSent ? (
                    <>
                        <Input
                            type={"email"}
                            name={"newEmail"}
                            value={changeEmailFormData.newEmail}
                            onChange={changeFormData}
                            placeholder={t("change_email.enter_new_email") + ""}
                            isError={emailError || changeEmailFormData.newEmailLocalError}
                            label={t("change_email.set_new_email")}
                        />
                        <button onClick={changeEmail} disabled={!isNewEmailCorrect || !!passwordStatus} className={styles.text_btn}>
                            {t("sundry.confirm")}
                        </button>
                    </>
                ) : (
                    <div className={styles.info_step}>
                        <h2 className={`${styles.disabled_part_header} ${confirmCodeSent && styles.confirmed}`}>
                            <span>{t("change_email.set_new_email")}</span>
                        </h2>
                        {confirmCodeSent && <CheckedIcon />}
                    </div>
                )}
            </div>
            <div className={styles.change_step}>
                {confirmCodeSent ? (
                    <div className={styles.confirm_code_part}>
                        <div className={styles.confirm_code_text_place}>
                            <h2 className={styles.send_code_title}>{t("change_email.new_email_has_set")}</h2>
                            <p className={styles.send_code_subtitle}>{t("change_email.we_sent_verif_code")}</p>
                        </div>
                        <div className={styles.confirm_code_input_place} onClick={() => dispatch(resetInvalidConfirmCodeRedux())}>
                            <VerificationInput
                                length={10}
                                autoFocus={true}
                                onChange={toggleNewEmailConfirmCode}
                                value={changeEmailFormData.confirmNewEmail}
                                classNames={{
                                    container: styles.vf_container,
                                    character: `${styles.vf_character} ${isConfirmCodeInvalid && styles.active}`,
                                    characterInactive: styles.vf_character_inactive,
                                    characterSelected: `${styles.vf_character_selected} ${isConfirmCodeInvalid && styles.active}`,
                                }}
                            />
                            <button className={styles.text_btn} onClick={confirmNewEmailConfirmationCode}>
                                {t("sundry.confirm")}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.info_step}>
                        <h2 className={`${styles.disabled_part_header}`}>
                            <span>{t("change_email.verify_new_email")}</span>
                        </h2>
                        {confirmCodeSent && <CheckedIcon />}
                    </div>
                )}
            </div>
        </>
    );
};

export default ChangeEmail;
