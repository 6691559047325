import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { coming_delay, coming_from_top_anime } from "../../../../assets/animations";
import GrainyGradient from "../../../../helperComponents/GrainyGradient";
import styles from "./app_photos.module.scss";

const AppPhotos = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.app_photo_wrapper}>
            <GrainyGradient />
            <div className={styles.first}>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={0.2}></motion.div>
                <div className={styles.text}>
                    <motion.h2 initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_from_top_anime} custom={40}>
                        {t("appPhotos.user_experience")}
                    </motion.h2>
                </div>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={0.4}></motion.div>
            </div>
            <div className={styles.second}>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={0.6}></motion.div>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={0.8}></motion.div>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={1}></motion.div>
            </div>
            <div className={styles.third}>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={1.2}>
                    {t("appPhotos.check_our_feature")}
                </motion.div>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={coming_from_top_anime}
                    custom={-40}
                    className={styles.text}
                ></motion.div>
                <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={1.4}></motion.div>
            </div>
        </div>
    );
};

export default AppPhotos;
