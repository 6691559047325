import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { comeing_anime, coming_delay, coming_from_top_anime, opacity_anime } from "../../../../assets/animations";
import GrainyGradient from "../../../../helperComponents/GrainyGradient";
import RoundedButton from "../../../../helperComponents/RoundedButton";
import SectionSubtitle from "../../../../helperComponents/SectionSubtitle";
import SectionTitle from "../../../../helperComponents/SectionTitile";
import styles from "./andtidetected_browser.module.scss";

const AntdtidetectedBrowser = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.antdtidetected_wrapper}>
            <GrainyGradient />
            <div className={styles.antdtidetected_main}>
                <div className={styles.animated_blocks}>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={comeing_anime}
                        custom={-650}
                        className={styles.gray_line}
                    ></motion.div>
                    <div className={styles.right_blocks}>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={comeing_anime}
                            custom={-100}
                            className={styles.up_blocks}
                        >
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={opacity_anime}
                                custom={1}
                                className={styles.rounded}
                            ></motion.div>
                        </motion.div>
                        <div className={styles.down_blocks}>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={coming_delay}
                                custom={1.2}
                                className={styles.small}
                            ></motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={coming_delay}
                                custom={0.9}
                                className={styles.medium}
                            ></motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={coming_delay}
                                custom={0.6}
                                className={styles.large}
                            ></motion.div>
                            <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={coming_delay}
                                custom={0.3}
                                className={styles.square}
                            ></motion.div>
                        </div>
                    </div>
                </div>
                <div className={styles.info_button_wrapper}>
                    <div className={styles.title_subtitle}>
                        <SectionSubtitle text={t("andtidetected.newEra")} style={{ fontSize: "20px" }} />
                        <SectionTitle text={t("andtidetected.andtidetectedBrowser")} style={{ fontSize: "120px", fontFamily: "Geo-300" }} />
                    </div>
                    <div className={styles.button_text}>
                        <motion.p
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={coming_from_top_anime}
                            custom={50}
                            className={styles.slogan}
                        >
                            {t("andtidetected.making_the_work")}
                        </motion.p>
                        <RoundedButton text={t("andtidetected.see_subscribe")} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AntdtidetectedBrowser;
