import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store";
import { setModalTypeRedux } from "../../store/features/modalSlice";
import { constants } from "../../assets/constants";
import { motion } from "framer-motion";
import { coming_delay, coming_from_top_anime, opacity_anime, scale_anime } from "../../assets/animations";
import { Select } from "antd";
import enFlag from "../../assets/images/flags/us.png";
import ruFlag from "../../assets/images/flags/ru.png";
import cnFlag from "../../assets/images/flags/cn.png";
import bnFlag from "../../assets/images/flags/bn.png";
import MASQTextLogo from "../../assets/images/svg/MASQTextLogo";
import MenuIcon from "../../assets/images/svg/MenuIcon";
import SectionSubtitle from "../../helperComponents/SectionSubtitle";
import styles from "./header.module.scss";
import { Link } from "react-router-dom";
import { helpers } from "../../helpers";

const Header = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        user: { emailConfirmed, name },
    } = useAppSelector((state) => state.account);
    const { modalTypes, paths } = constants;

    const languageMenuItems = useMemo(
        () => [
            {
                id: 10,
                value: "en",
                label: (
                    <p className={styles.lng_name}>
                        <img src={enFlag} /> {t("countries.us")}
                    </p>
                ),
            },
            {
                id: 12,
                value: "bn",
                label: (
                    <p className={styles.lng_name}>
                        <img src={bnFlag} /> {t("countries.bn")}
                    </p>
                ),
            },
            // {
            //     id: 11,
            //     value: "ru",
            //     label: (
            //         <p className={styles.lng_name}>
            //             <img src={ruFlag} /> {t("countries.ru")}
            //         </p>
            //     ),
            //     disabled: true,
            // },
            // {
            //     id: 13,
            //     value: "cn",
            //     label: (
            //         <p className={styles.lng_name}>
            //             <img src={cnFlag} /> {t("countries.cn")}
            //         </p>
            //     ),
            //     disabled: true,
            // },
        ],
        [t]
    );

    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>(helpers.getLanguage() || languageMenuItems[0].value);

    const openModal = () => dispatch(setModalTypeRedux({ type: modalTypes.download }));

    const changeLang = (value: string) => {
        setLanguage(value);
        i18n.changeLanguage(value);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            let value = "";
            const isShiftPressed = event.shiftKey;
            if (event.key === "B" || event.key === "b") value = "bn";
            if (event.key === "E" || event.key === "e") value = "en";
            if (isShiftPressed && value) changeLang(value);
        };
        if (!document.onkeydown) document.onkeydown = handleKeyPress;
    }, []);

    return (
        <header className={`${styles.main_header} ${isScrolled && styles.scrolled}`}>
            <div className={styles.header_wrapper}>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={coming_from_top_anime}
                    custom={50}
                    className={styles.logo}
                >
                    <MASQTextLogo />
                </motion.div>
                <div className={styles.nav_sign_in_wrapper}>
                    <nav className={styles.main_nav}>
                        <motion.ul initial="hidden" whileInView="visible" viewport={{ once: true }} variants={coming_delay} custom={0.4}>
                            <li>
                                <a href="#prices">{t("nav.pricing")}</a>
                            </li>
                            <li>
                                <a href={process.env.REACT_APP_TELEGRAM_LINK} target="_blank">
                                    {t("sundry.support")}
                                </a>
                            </li>
                            <li onClick={openModal}>{t("sundry.download")}</li>
                        </motion.ul>
                    </nav>
                    <Link to={paths.signIn} className={styles.router_sign_in}>
                        {!emailConfirmed ? (
                            <div className={styles.sign_in}>{t("sundry.signIn")}</div>
                        ) : (
                            <div className={styles.user_icon}>{name[0].toUpperCase()}</div>
                        )}
                    </Link>
                    {/* <Select
                        value={language}
                        options={languageMenuItems}
                        style={{ width: 150 }}
                        onChange={(e) => changeLang(e)}
                        className={styles.select}
                    /> */}
                    <div className={styles.hamburger_wrapper}>
                        <input
                            type="checkbox"
                            id="checkbox"
                            checked={isBurgerOpen}
                            onChange={(e) => setIsBurgerOpen(e.target.checked)}
                            className={styles.forToggle}
                        />
                        <label htmlFor="checkbox" className={styles.menu_btn}>
                            <MenuIcon />
                        </label>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={opacity_anime}
                            custom={0.3}
                            className={styles.hamburger_background}
                        >
                            <SectionSubtitle text={t("sundry.menu")} style={{ fontSize: "12px" }} />
                            <motion.ul
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={scale_anime}
                                className={styles.hamburger_main}
                            >
                                <li className={styles.hamburger_item} onClick={() => setIsBurgerOpen(false)}>
                                    <label htmlFor="checkbox">
                                        <a href="#prices">{t("nav.pricing")}</a>
                                    </label>
                                </li>
                                <li className={styles.hamburger_item} onClick={() => setIsBurgerOpen(false)}>
                                    <label htmlFor="checkbox">
                                        <a href={process.env.REACT_APP_TELEGRAM_LINK} target="_blank">
                                            {t("sundry.support")}
                                        </a>
                                    </label>
                                </li>
                                <li className={styles.hamburger_item} onClick={openModal}>
                                    <label htmlFor="checkbox">{t("sundry.download")}</label>
                                </li>
                            </motion.ul>
                        </motion.div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
