import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { constants } from "../../assets/constants";
import {
    AccountSliceInitialStateTypes,
    BuySubscriptionPlanAsyncTypes,
    BuyTeamPlanAsyncTypes,
    ChangeTariffAsyncTypes,
    CheckPromoCodeAsyncTypes,
    CheckRecoveryCodeAsyncTypes,
    ConfirmEmailAsyncTypes,
    LogOutAsyncTypes,
    LoginAsyncTypes,
    PaymentsTariffAsyncAsync,
    PaymentsTariffTeamAsyncAsync,
    RecoveryPasswordAsyncTypes,
    TokenTypes,
    VerifyTwoFactorAuthenticationAsyncTypes,
} from "../../types";
import { t } from "i18next";
import { helpers } from "../../helpers";
import { showNotifyRedux } from "./notifySlice";
import { resetModalStateToInitialRedux, resetMoreBenefitsShowRedux } from "./modalSlice";

const { endpoints, bearer, notifyTypes, masqWallet } = constants;
const hash: string = helpers.hash();

export const loginAsync = createAsyncThunk("account/loginAsync", async ({ formData: { name, password, isSigned, tfaToken } }: LoginAsyncTypes) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoints.userLogin, {
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            os: `${window.navigator.platform} ${window.navigator.userAgent}`,
            cpu: window.navigator.appVersion,
            app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
            hash,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
            name,
            password,
            tfaToken,
        }),
    });
    const data = await response.json();
    if (data.message) throw new Error(data.message);
    data.isSigned = isSigned;
    return data;
});

export const loginByTokenAsync = createAsyncThunk("account/loginByTokenAsync", async ({ token }: TokenTypes) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoints.userAuth, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
            os: `${window.navigator.platform} ${window.navigator.userAgent}`,
            cpu: window.navigator.appVersion,
            app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
            hash,
        },
    });
    const data = await response.json();
    if (data.message) throw new Error(data.message);
    data.token = token;
    return data;
});

export const confirmEmailAsync = createAsyncThunk(
    "account/confirmEmailAsync",
    async ({ confirmCode, navigate }: ConfirmEmailAsyncTypes, { dispatch }) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.confirmEmail + confirmCode, {
            method: "GET",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
        });
        const data = await response.json();
        if (data.message) throw new Error(data.message);
        data.hash = hash;
        navigate();
        dispatch(showNotifyRedux({ type: notifyTypes.success, title: t("signUp.created_acc") }));
        return data;
    }
);

export const sendRecoveryCodeAsync = createAsyncThunk("account/sendRecoveryCodeAsync", async ({ name }: { name: string }, { dispatch }) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoints.sendRecoveryCode, {
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            os: `${window.navigator.platform} ${window.navigator.userAgent}`,
            cpu: window.navigator.appVersion,
            app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
            hash,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
            name,
        }),
    });
    const data = await response.json();
    if (data.message || data.error) {
        dispatch(showNotifyRedux({ type: notifyTypes.error, title: data.message || data.error }));
        throw new Error(data.message);
    }
    return data;
});

export const checkRecoveryCodeAsync = createAsyncThunk(
    "account/checkRecoveryCodeAsync",
    async ({ name, recoveryCode, changePageFn }: CheckRecoveryCodeAsyncTypes) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.checkRecoveryCode, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                name,
                recoveryCode,
            }),
        });
        const data = await response.json();
        if (data.message) throw new Error(data.message);
        changePageFn({ type: "page", value: "3" });
        return data;
    }
);

export const recoveryPasswordAsync = createAsyncThunk(
    "account/recoveryPasswordAsync",
    async ({ name, recoveryCode, password, goToSignIn }: RecoveryPasswordAsyncTypes, { dispatch }) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.recoveryPassword, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                name,
                recoveryCode,
                password,
            }),
        });
        const data = await response.json();
        if (data.message) throw new Error(data.message);
        goToSignIn();
        dispatch(showNotifyRedux({ type: notifyTypes.success, title: t("signIn.you_have_new_pass") }));
        dispatch(resetAccountStateToInitialRedux());
        return data;
    }
);

export const logOutAsync = createAsyncThunk("account/logOutAsync", async ({ token }: LogOutAsyncTypes, { dispatch }) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoints.logOut, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
            os: `${window.navigator.platform} ${window.navigator.userAgent}`,
            cpu: window.navigator.appVersion,
            app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
            hash,
        },
    });
    const data = await response.json();
    if (data.message) throw new Error(data.message);
    dispatch(resetAccountStateToInitialRedux());
    dispatch(resetMoreBenefitsShowRedux());
    return data;
});

export const removeTwoFactorAuthenticationAsync = createAsyncThunk("account/removeTwoFactorAuthenticationAsync", async ({ token }: TokenTypes) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoints.removeTfa, {
        method: "GET",
        credentials: "same-origin",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
            os: `${window.navigator.platform} ${window.navigator.userAgent}`,
            cpu: window.navigator.appVersion,
            app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
            hash,
        },
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message);
    return false;
});

export const verifyTwoFactorAuthenticationAsync = createAsyncThunk(
    "account/verifyTwoFactorAuthenticationAsync",
    async ({ token, confirmCode }: VerifyTwoFactorAuthenticationAsyncTypes, { dispatch }) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.verifyTfa, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                token: confirmCode,
            }),
        });
        const data = await response.json();
        if (!response.ok) throw new Error(data.message);
        dispatch(resetModalStateToInitialRedux());
        return true;
    }
);

export const setupTwoFactorAuthenticationAsync = createAsyncThunk("account/setupTwoFactorAuthenticationAsync", async ({ token }: TokenTypes) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoints.setupTfa, {
        method: "GET",
        credentials: "same-origin",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
            os: `${window.navigator.platform} ${window.navigator.userAgent}`,
            cpu: window.navigator.appVersion,
            app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
            hash,
        },
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message);
    return data;
});

export const checkPromoCodeAsync = createAsyncThunk(
    "account/checkPromoCodeAsync",
    async ({ token, promocode, type, goToAccountPage }: CheckPromoCodeAsyncTypes, { dispatch }) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.promoCode + `${type}/` + promocode, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
        });
        const data = await response.json();
        if (data.message) {
            dispatch(showNotifyRedux({ type: notifyTypes.error, title: data.message }));
            throw new Error(data.message + type);
        }
        if (type === "use") {
            goToAccountPage && goToAccountPage();
            dispatch(showNotifyRedux({ type: notifyTypes.invite, title: t("promocode.promocode_has_been_activated") + "" }));
        }
        return { data, promocode, type };
    }
);

export const buySubscriptionPlanAsync = createAsyncThunk(
    "account/buySubscriptionPlanAsync",
    async ({ token, subId, promoCode, closePaymentPageFn, subName }: BuySubscriptionPlanAsyncTypes, { dispatch }) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.buySubscriptionPlan, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                subId,
                promoCode,
            }),
        });
        const data = await response.json();
        if (data.message || data.error) throw new Error(data.message);
        closePaymentPageFn();
        dispatch(
            showNotifyRedux({
                type: notifyTypes.invite,
                title: t("notifyMessages.subscription_has_been_activated") + "",
                subTitle: `${t("notifyMessages.now_you_have")} ${helpers.patchSubscriptionName(t, subName)} ${t(
                    "notifyMessages.subscription_on_account"
                )}`,
            })
        );
        return data;
    }
);

export const buyTeamPlanAsync = createAsyncThunk(
    "account/buyTeamPlanAsync",
    async ({ token, tariffId, promoCode, closePaymentPageFn, seatCount, subName }: BuyTeamPlanAsyncTypes, { dispatch }) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.buyTeamPlan + (promoCode && `?promoCode=${promoCode}`), {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                tariffId,
                seatCount,
            }),
        });
        const data = await response.json();
        if (data.message || data.error) throw new Error(data.message);
        closePaymentPageFn();
        dispatch(
            showNotifyRedux({
                type: notifyTypes.invite,
                title: t("notifyMessages.subscription_has_been_activated") + "",
                subTitle: `${t("notifyMessages.now_you_have")} ${helpers.patchSubscriptionName(t, subName)} ${t(
                    "notifyMessages.subscription_on_account"
                )}`,
            })
        );
        return data;
    }
);

export const paymentsTariffAsync = createAsyncThunk(
    "account/paymentsTariffAsync",
    async ({ token, tariffId, currency, promoCode }: PaymentsTariffAsyncAsync) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.paymentsTariff, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            body: JSON.stringify({
                tariffId,
                currency,
                promoCode,
            }),
        });
        const data = await response.json();
        if (data.message) throw new Error(data.message);
        return data;
    }
);

export const paymentsTariffForTeamAsync = createAsyncThunk(
    "account/paymentsTariffForTeamAsync",
    async ({ token, tariffId, currency, promoCode, seatCount }: PaymentsTariffTeamAsyncAsync) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.paymentsTariffTeam, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            body: JSON.stringify({
                tariffId,
                currency,
                seatCount,
                promoCode,
            }),
        });
        const data = await response.json();
        if (data.message) throw new Error(data.message);
        return data;
    }
);

export const getCryptomusCurrencies = createAsyncThunk("account/getCryptomusCurrencies", async ({ token }: TokenTypes, { dispatch }) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoints.getCryptomusCurrencies, {
        method: "GET",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
            os: `${window.navigator.platform} ${window.navigator.userAgent}`,
            cpu: window.navigator.appVersion,
            app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
            hash,
        },
    });
    const data = await response.json();
    if (data.message) {
        dispatch(showNotifyRedux({ type: notifyTypes.error, title: t("notifyMessages.oops_title") }));
        throw new Error(data.message);
    }
    return data;
});

export const changeTariffAsync = createAsyncThunk(
    "account/changeTariffAsync",
    async ({ token, subId, tariffId, subName, hideNotify }: ChangeTariffAsyncTypes, { dispatch }) => {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoints.changeTariff, {
            method: "POST",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                os: `${window.navigator.platform} ${window.navigator.userAgent}`,
                cpu: window.navigator.appVersion,
                app: "MASQ Panel " + process.env.REACT_APP_APP_VERSION,
                hash,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                subId,
                tariffId,
            }),
        });
        const data = await response.json();
        if (data.message || data.error) {
            dispatch(
                showNotifyRedux({
                    type: notifyTypes.error,
                    title: data.message || data.error,
                })
            );
            throw new Error(data.message);
        }
        dispatch(resetModalStateToInitialRedux());
        !hideNotify &&
            dispatch(
                showNotifyRedux({
                    type: notifyTypes.invite,
                    title: t("notifyMessages.subscription_has_been_activated") + "",
                    subTitle: `${t("notifyMessages.now_you_have")} ${helpers.patchSubscriptionName(t, subName)} ${t(
                        "notifyMessages.subscription_on_account"
                    )}`,
                })
            );
        return data;
    }
);

const activeSub = {
    type: "",
    tariffId: "",
    tariffWeight: NaN,
    buyTime: NaN,
    duration: NaN,
    userLimit: NaN,
    profileLimit: NaN,
    profileCount: NaN,
    users: [],
    owner: "",
    activated: false,
    ended: false,
    deactivated: false,
    createAt: NaN,
    _id: "",
    end: NaN,
    start: NaN,
    tariffDuration: NaN,
    profileCountResetTime: NaN,
};

const initialState: AccountSliceInitialStateTypes = {
    accountLoading: false,
    confirmCodeSent: false,
    recoveryEmail: "",
    isConfirmCodeValid: false,
    isConfirmCodeInvalid: false,
    isTfaConfirmCodeInvalid: false,
    paymentUrl: "",
    paymentTariffUrl: "",
    signInError: "",
    nameError: "",
    passwordStatus: "",
    requiredTfa: false,
    promoCodeError: "",
    discountedTariffList: [],
    tfaInfos: {
        message: "",
        dataURL: "",
        secretKey: "",
    },
    user: {
        name: "",
        email: "",
        balance: NaN,
        invites: [],
        invitesDisabled: false,
        confirmCodeResendTimeout: undefined,
        profileReceiveDisabled: false,
        profileFolderReceiveDisabled: false,
        type: "",
        messages: [],
        emailConfirmed: null,
        tfaEnabled: false,
        passwordUpdateAt: undefined,
        sessions: [],
        sharedData: [],
    },
    teams: [],
    memberSessionsList: [],
    activeSub,
    teamsInfo: {
        name: "",
        users: [{ id: "", name: "", email: "", dateAdded: NaN, role: "" }],
        owner: {
            id: "",
            name: "",
            email: "",
        },
        inviteCode: "",
        createAt: NaN,
        _id: "",
        userLimit: NaN,
        subs: [],
        activeSub,
    },
    token: "",
    hash: helpers.hash(),
    manageTeamInfo: {
        name: "",
        users: [],
        owner: {
            name: "",
            email: "",
        },
        inviteCode: "",
        createAt: 0,
        _id: "",
        userLimit: 0,
        subs: [],
        activeSub,
    },
    activatedPromocode: "",
    cryptomusCurrenciesList: [],
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        resetInvalidConfirmCodeRedux(state) {
            state.isConfirmCodeInvalid = false;
        },
        resetByDefaultAccountRedux(state) {
            state.confirmCodeSent = false;
            state.isConfirmCodeValid = false;
            state.isConfirmCodeInvalid = false;
            state.signInError = "";
            state.recoveryEmail = "";
            state.tfaInfos = initialState.tfaInfos;
            state.isTfaConfirmCodeInvalid = false;
            state.promoCodeError = "";
            state.paymentUrl = "";
            state.discountedTariffList = [];
        },
        resetRecoveryEmail(state) {
            state.recoveryEmail = "";
        },
        resetAccountStateToInitialRedux(state) {
            localStorage.removeItem("token");
            state.accountLoading = initialState.accountLoading;
            state.confirmCodeSent = initialState.confirmCodeSent;
            state.recoveryEmail = initialState.recoveryEmail;
            state.isConfirmCodeValid = initialState.isConfirmCodeValid;
            state.isConfirmCodeInvalid = initialState.isConfirmCodeInvalid;
            state.isTfaConfirmCodeInvalid = initialState.isTfaConfirmCodeInvalid;
            state.signInError = initialState.signInError;
            state.nameError = initialState.nameError;
            state.passwordStatus = initialState.passwordStatus;
            state.requiredTfa = initialState.requiredTfa;
            state.tfaInfos = initialState.tfaInfos;
            state.user = initialState.user;
            state.teams = initialState.teams;
            state.activeSub = initialState.activeSub;
            state.token = initialState.token;
        },
        changeNameRedux(state, action) {
            state.user.name = action.payload.data.user.name;
        },
        changePasswordRedux(state, action) {
            state.user = action.payload;
        },
        resetTfaConfirmCode(state) {
            state.isTfaConfirmCodeInvalid = false;
        },
        resetTfaInfos(state) {
            state.tfaInfos = initialState.tfaInfos;
        },
        changeConfirmCodeResendTimeoutRedux(state, action) {
            state.user.confirmCodeResendTimeout = action.payload;
        },
        changeEmailRedux(state, action) {
            state.user.email = action.payload;
        },
        resetTfaCodeData(state) {
            state.isTfaConfirmCodeInvalid = false;
            state.requiredTfa = false;
        },
        resetPaymentTariffUrlRedux(state) {
            state.paymentTariffUrl = "";
        },
        resetPromoCodeErrorRedux(state) {
            state.promoCodeError = "";
        },
        resetActivePromocodeRedux(state) {
            state.activatedPromocode = "";
            state.discountedTariffList = initialState.discountedTariffList;
        },
        resetDiscountedTariffListRedux(state) {
            state.discountedTariffList = [];
            state.paymentTariffUrl = "";
            state.activatedPromocode = "";
            state.promoCodeError = "";
        },
        resetSignInErrorRedux(state) {
            state.signInError = "";
        },
        changeUserDataAfterRegistrationRedux(state, action) {
            state.user = action.payload.user;
            state.activeSub = action.payload.activeSub || initialState.activeSub;
            state.teams = action.payload.teams || initialState.teams;
            state.token = bearer + action.payload.token;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.signInError = "";
                state.user = action.payload.user;
                state.activeSub = action.payload.activeSub || initialState.activeSub;
                state.teams = action.payload.teams || initialState.teams;
                state.token = bearer + action.payload.token;
                state.requiredTfa = false;
                state.isTfaConfirmCodeInvalid = false;
                if (action.payload.isSigned) localStorage.setItem("token", bearer + action.payload.token);
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.accountLoading = false;
                if (action.error.message === "Please enter the 2FA Auth Code") {
                    state.requiredTfa = true;
                } else if (action.error.message === "Invalid 2FA Auth Code") {
                    state.isTfaConfirmCodeInvalid = true;
                } else {
                    state.signInError = t("signIn.incorrect_username_password");
                }
            })
            .addCase(loginByTokenAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.activeSub = action.payload.activeSub || initialState.activeSub;
                state.teams = action.payload.teams || initialState.teams;
            })
            .addCase(loginByTokenAsync.rejected, (state, action) => {
                state.accountLoading = false;
            })
            .addCase(confirmEmailAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(confirmEmailAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.isConfirmCodeInvalid = false;
                state.isConfirmCodeValid = true;
                state.user = action.payload.user;
                state.token = bearer + action.payload.token;
                state.hash = action.payload.hash;
                localStorage.setItem("token", bearer + action.payload.token);
            })
            .addCase(confirmEmailAsync.rejected, (state) => {
                state.accountLoading = false;
                state.isConfirmCodeInvalid = true;
            })
            .addCase(sendRecoveryCodeAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(sendRecoveryCodeAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.recoveryEmail = action.payload.email;
            })
            .addCase(sendRecoveryCodeAsync.rejected, (state) => {
                state.accountLoading = false;
            })
            .addCase(checkRecoveryCodeAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(checkRecoveryCodeAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.isConfirmCodeValid = action.payload.valid;
                state.isConfirmCodeInvalid = !action.payload.valid;
            })
            .addCase(checkRecoveryCodeAsync.rejected, (state) => {
                state.accountLoading = false;
            })
            .addCase(logOutAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(logOutAsync.fulfilled, (state) => {
                state.accountLoading = false;
                state.confirmCodeSent = false;
                state.user = { ...initialState.user };
                state.activeSub = { ...initialState.activeSub };
                state.token = "";
                localStorage.removeItem("token");
            })
            .addCase(logOutAsync.rejected, (state, action) => {
                state.accountLoading = false;
            })
            .addCase(removeTwoFactorAuthenticationAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(removeTwoFactorAuthenticationAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.user.tfaEnabled = action.payload;
            })
            .addCase(removeTwoFactorAuthenticationAsync.rejected, (state) => {
                state.accountLoading = false;
            })
            .addCase(verifyTwoFactorAuthenticationAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(verifyTwoFactorAuthenticationAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.isTfaConfirmCodeInvalid = false;
                state.user.tfaEnabled = action.payload;
            })
            .addCase(verifyTwoFactorAuthenticationAsync.rejected, (state) => {
                state.accountLoading = false;
                state.isTfaConfirmCodeInvalid = true;
            })
            .addCase(setupTwoFactorAuthenticationAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(setupTwoFactorAuthenticationAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.tfaInfos = action.payload;
            })
            .addCase(setupTwoFactorAuthenticationAsync.rejected, (state) => {
                state.accountLoading = false;
            })
            .addCase(checkPromoCodeAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(checkPromoCodeAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.promoCodeError = initialState.promoCodeError;
                if (action.payload.type === "check") {
                    state.discountedTariffList = action.payload.data;
                    state.activatedPromocode = action.payload.promocode;
                } else if (action.payload.type === "use") {
                    state.user = action.payload.data.user;
                    if (action.payload.data.activeSub) {
                        state.activeSub = action.payload.data.activeSub;
                    }
                    if (action.payload.data.teams) {
                        state.teams = action.payload.data.teams;
                    }
                    state.token = bearer + action.payload.data.token;
                }
            })
            .addCase(checkPromoCodeAsync.rejected, (state, action) => {
                const slicedCount = action.error.message?.slice(-5) === "check" ? 5 : 3;
                state.accountLoading = false;
                state.promoCodeError = (action.error.message + "").slice(0, slicedCount * -1);
                if (action.error.message?.includes("check")) {
                    state.discountedTariffList = initialState.discountedTariffList;
                    state.activatedPromocode = "";
                }
            })
            .addCase(buySubscriptionPlanAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(buySubscriptionPlanAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.user = action.payload.user;
                if (action.payload.activeSub) {
                    state.activeSub = action.payload.activeSub;
                }
                if (action.payload.teams) {
                    state.teams = action.payload.teams;
                }
                state.token = bearer + action.payload.token;
                localStorage.setItem("token", bearer + action.payload.token);
            })
            .addCase(buySubscriptionPlanAsync.rejected, (state, action) => {
                state.accountLoading = false;
            })
            .addCase(buyTeamPlanAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(buyTeamPlanAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.user = action.payload.user;
                if (action.payload.activeSub) {
                    state.activeSub = action.payload.activeSub;
                }
                state.teams = action.payload.teams;
                state.token = bearer + action.payload.token;
                localStorage.setItem("token", bearer + action.payload.token);
            })
            .addCase(buyTeamPlanAsync.rejected, (state, action) => {
                state.accountLoading = false;
            })
            .addCase(paymentsTariffAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.paymentTariffUrl = action.payload.url;
            })
            .addCase(paymentsTariffAsync.rejected, (state) => {
                state.accountLoading = false;
            })
            .addCase(paymentsTariffForTeamAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.paymentTariffUrl = action.payload.url;
            })
            .addCase(paymentsTariffForTeamAsync.rejected, (state) => {
                state.accountLoading = false;
            })
            .addCase(getCryptomusCurrencies.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(getCryptomusCurrencies.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.cryptomusCurrenciesList = [masqWallet, ...action.payload];
            })
            .addCase(getCryptomusCurrencies.rejected, (state, action) => {
                state.accountLoading = false;
            })
            .addCase(changeTariffAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(changeTariffAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                state.user = action.payload.user || state.user;
                state.activeSub = action.payload.activeSub || state.activeSub;
            })
            .addCase(changeTariffAsync.rejected, (state, action) => {
                state.accountLoading = false;
            });
    },
});

export const {
    resetInvalidConfirmCodeRedux,
    resetByDefaultAccountRedux,
    changePasswordRedux,
    resetRecoveryEmail,
    resetAccountStateToInitialRedux,
    changeNameRedux,
    resetTfaConfirmCode,
    resetTfaInfos,
    changeEmailRedux,
    resetTfaCodeData,
    resetPaymentTariffUrlRedux,
    resetPromoCodeErrorRedux,
    resetActivePromocodeRedux,
    resetDiscountedTariffListRedux,
    resetSignInErrorRedux,
    changeUserDataAfterRegistrationRedux,
    changeConfirmCodeResendTimeoutRedux,
} = accountSlice.actions;

export default accountSlice.reducer;
