import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import modalSlice from "./features/modalSlice";
import accountSlice from "./features/accountSlice";
import signupSlice from "./features/signupSlice";
import notifySlice from "./features/notifySlice";
import settingsSlice from "./features/settingsSlice";
import tariffSlice from "./features/tariffSlice";
import apiMiddleware from "./misddlewares/apiMiddleware";

export const store = configureStore({
    reducer: {
        modal: modalSlice,
        account: accountSlice,
        signUp: signupSlice,
        notify: notifySlice,
        settings: settingsSlice,
        tariff: tariffSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiMiddleware),
});

type AppDispatch = typeof store.dispatch;
type RootState = ReturnType<typeof store.getState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
