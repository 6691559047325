import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ReactComponent as UserIcon } from "../../../assets/images/svg/accountSVG/user.svg";
import { ReactComponent as SecurityIcon } from "../../../assets/images/svg/accountSVG/security.svg";
import { ReactComponent as MonitorIcon } from "../../../assets/images/svg/accountSVG/monitor.svg";
import { setModalTypeRedux } from "../../../store/features/modalSlice";
import { constants } from "../../../assets/constants";
import { Link } from "react-router-dom";
import styles from "./account.module.scss";
import { checkPromoCodeAsync, resetPromoCodeErrorRedux } from "../../../store/features/accountSlice";
import { useEffect, useState } from "react";
import { helpers } from "../../../helpers";

const Account = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { activeSub, token } = useAppSelector((state) => state.account);
    const { modalTypes, paths } = constants;

    const [promocode, setPromocode] = useState<string>("");

    const openDownload = () => dispatch(setModalTypeRedux({ type: modalTypes.download }));
    const openSettings = () => dispatch(setModalTypeRedux({ type: modalTypes.settings }));
    const openSecuritySettings = () => dispatch(setModalTypeRedux({ type: modalTypes.security }));

    const activatePromoCode = () => {
        dispatch(checkPromoCodeAsync({ token, promocode: promocode, type: "use" }));
    };

    useEffect(() => {
        return () => {
            dispatch(resetPromoCodeErrorRedux());
        };
    }, []);

    return (
        <div className={styles.account_wrapper}>
            <div className={styles.account_main}>
                <div className={styles.sub_promo_wrapper}>
                    <div className={styles.sub_wrapper}>
                        <div className={styles.sub_info_wrapper}>
                            <div className={styles.sub_info}>
                                <p className={styles.tarrif_title}>{t("account.tarrif")}</p>
                                <p className={styles.tarrit_name}>{activeSub.type || t("account.no_sub")}</p>
                            </div>
                            <div className={styles.sub_status}>
                                <p className={styles.status} style={{ background: activeSub.type ? "#26AF60" : "#E00252" }}>
                                    {activeSub.type ? t("account.activated") : t("account.not_active")}
                                </p>
                                {activeSub.type && (
                                    <p className={styles.active_date}>
                                        <span>{t("account.valid_until")}</span>
                                        <span> {activeSub.end ? helpers.correctTimeForUTC(activeSub.end) : t("sundry.unlimited")}</span>
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={styles.btns_wrapper}>
                            <Link to={paths.tariff}>{activeSub.type ? t("sundry.manage") : t("account.buy_sub")}</Link>
                        </div>
                    </div>
                    <div className={styles.promo_wrapper}>
                        <p className={styles.promo_title}>{t("account.enter_promo")}</p>
                        <p className={styles.promo_subtitle}>{t("account.enter_promo_subtitle")}</p>
                        <input
                            type="text"
                            placeholder={t("sundry.code")}
                            className={styles.promo_inp}
                            value={promocode}
                            onChange={(e) => setPromocode(e.target.value)}
                        />
                        <div className={styles.promo_btns}>
                            <button className={styles.activate_promo} onClick={activatePromoCode} disabled={!promocode.length}>
                                {t("account.activated")}
                            </button>
                            <a href={process.env.REACT_APP_TELEGRAM_LINK} target="_blank" className={styles.cant_activate}>
                                {t("account.cant_active")}
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.blocks_wrapper}>
                    <div className={styles.block} onClick={openSettings}>
                        <div className={styles.icon}>
                            <UserIcon />
                        </div>
                        <div className={styles.blocks_info}>
                            <p className={styles.block_title}>{t("account.account_management")}</p>
                            <p className={styles.block_subtitle}>{t("account.account_management_subtitle")}</p>
                        </div>
                    </div>
                    <div className={styles.block} onClick={openSecuritySettings}>
                        <div className={styles.icon}>
                            <SecurityIcon />
                        </div>
                        <div className={styles.blocks_info}>
                            <p className={styles.block_title}>{t("account.security")}</p>
                            <p className={styles.block_subtitle}>{t("account.security_subtitle")}</p>
                        </div>
                    </div>
                    <div className={styles.block} onClick={openDownload}>
                        <div className={styles.icon}>
                            <MonitorIcon />
                        </div>
                        <div className={styles.blocks_info}>
                            <p className={styles.block_title}>{t("account.download_app")}</p>
                            <p className={styles.block_subtitle}>{t("account.download_app_subtitle")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
