import { useAppDispatch, useAppSelector } from "../../../../store";
import { useTranslation } from "react-i18next";
import { changeTariffAsync } from "../../../../store/features/accountSlice";
import styles from "./convert_subs.module.scss";

const ConvertSubs = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { configs } = useAppSelector((state) => state.modal);
    const {
        activeSub: { _id, duration, tariffWeight, type },
        token,
    } = useAppSelector((state) => state.account);

    const dateAfterConvert = configs?.weight === 1 ? duration / 86400000 : (duration * tariffWeight) / Number(configs?.weight) / 86400000;

    const days = +dateAfterConvert.toString().split(".")[0];
    const hours = +dateAfterConvert - days;
    const disabledChange = !days && +(24 * +hours + "").split(".")[0] < 4;

    const convertSubscriptions = () =>
        dispatch(changeTariffAsync({ token, subId: _id, tariffId: configs?.tariffId + "", subName: configs?.subName + "" }));

    return (
        <div className={styles.convert_subs_wrapper}>
            <p className={styles.convert_info}>
                <span>{t("convert_subs.you_will_have")}</span>
                <span>{days}</span> <span>{t("sundry.day")}</span>{" "}
                {!!hours && (
                    <>
                        <span>{(24 * +hours + "").split(".")[0]}</span>
                        <span> {t("sundry.hours")}</span>
                    </>
                )}{" "}
                <span>{t("sundry.of")}</span> <span>{configs?.subName}</span>
                <span>{t("convert_subs.you_will_have_continue")}</span>
            </p>
            <div className={styles.convert_wrapper}>
                <div className={styles.subtotal_info}>
                    <p className={styles.subtotal}>{t("convert_subs.subtotal")}</p>
                    <p className={styles.price}>0 $</p>
                </div>
                <ul className={styles.convert_info_wrapper}>
                    <li>{t("convert_subs.sub_typically_available")}</li>
                    <li>{t("convert_subs.vat_included")}</li>
                </ul>
                <button className={styles.black_btn} disabled={disabledChange} onClick={convertSubscriptions}>
                    {t("convert_subs.change_plan")}
                </button>
            </div>
        </div>
    );
};

export default ConvertSubs;
