import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { CurrenciesListTypes } from "../../../../../types";
import { getCryptomusCurrencies, resetPaymentTariffUrlRedux } from "../../../../../store/features/accountSlice";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import AVAX from "../../../../../assets/images/paymentsMethods/AVAX.png";
import BCH from "../../../../../assets/images/paymentsMethods/BCH.png";
import BNB from "../../../../../assets/images/paymentsMethods/BNB.png";
import BTC from "../../../../../assets/images/paymentsMethods/BTC.png";
import BUSD from "../../../../../assets/images/paymentsMethods/BUSD.png";
import CGPT from "../../../../../assets/images/paymentsMethods/CGPT.png";
import DAI from "../../../../../assets/images/paymentsMethods/DAI.png";
import DASH from "../../../../../assets/images/paymentsMethods/DASH.png";
import DOGE from "../../../../../assets/images/paymentsMethods/DOGE.png";
import ETH from "../../../../../assets/images/paymentsMethods/ETH.png";
import LTC from "../../../../../assets/images/paymentsMethods/LTC.png";
import MATIC from "../../../../../assets/images/paymentsMethods/MATIC.png";
import SOL from "../../../../../assets/images/paymentsMethods/SOL.png";
import TON from "../../../../../assets/images/paymentsMethods/TON.png";
import TRX from "../../../../../assets/images/paymentsMethods/TRX.png";
import USDC from "../../../../../assets/images/paymentsMethods/USDC.png";
import USDT from "../../../../../assets/images/paymentsMethods/USDT.png";
import VERSE from "../../../../../assets/images/paymentsMethods/VERSE.png";
import XMR from "../../../../../assets/images/paymentsMethods/XMR.png";
import SHIB from "../../../../../assets/images/paymentsMethods/SHIB.jpg";
import MASQ from "../../../../../assets/images/paymentsMethods/masq_wallet.png";
import _ from "lodash";
import styles from "./currencies_carousel.module.scss";

const currenciesLogo = { MASQ, BTC, VERSE, DAI, BUSD, ETH, USDT, DOGE, MATIC, CGPT, SOL, TON, BNB, USDC, TRX, LTC, AVAX, BCH, XMR, DASH, SHIB };
const currenciesLogoOrders = {
    MASQ: 1,
    USDT: 2,
    BTC: 3,
    VERSE: 4,
    DAI: 5,
    ETH: 6,
    USDC: 7,
    DOGE: 8,
    MATIC: 9,
    CGPT: 10,
    SOL: 11,
    TON: 12,
    BNB: 13,
    BUSD: 14,
    TRX: 15,
    LTC: 16,
    AVAX: 17,
    BCH: 18,
    XMR: 19,
    DASH: 20,
    SHIB: 21,
};
const currency_item_animation = {
    hidden: {
        opacity: 0,
        transform: `scale(0)`,
    },
    visible: (custom: number) => ({
        opacity: 1,
        transition: { delay: custom * 0.1 },
        transform: `scale(1)`,
    }),
};

function CurrenciesCarousel({ selectedCurrency, setSelectedCurrency }: { selectedCurrency: string; setSelectedCurrency: Function }) {
    const dispatch = useAppDispatch();
    const { token, cryptomusCurrenciesList } = useAppSelector((state) => state.account);
    const [showItemsCount, setShowItemsCount] = useState<number>(
        Math.floor((window.innerWidth > 1300 ? 650 : window.innerWidth - (window.innerWidth > 850 ? 500 : 80)) / 160)
    );
    const [showItemsStartIndex, setShowItemsStartIndex] = useState<number>(0);
    const [animation, setAnimation] = useState<string>("");
    const cList = useRef<CurrenciesListTypes[]>([]);

    const allCurrenciesList: CurrenciesListTypes[] = _.cloneDeep(cryptomusCurrenciesList)
        .sort((a, b) => currenciesLogoOrders[a as keyof typeof currenciesLogoOrders] - currenciesLogoOrders[b as keyof typeof currenciesLogoOrders])
        .map((cr) => ({
            id: cr,
            logo: currenciesLogo[cr.toUpperCase() as keyof typeof currenciesLogo],
            alt: cr,
        }));
    const list = cList.current.length ? cList.current : allCurrenciesList;
    const currenciesToShow = list.slice(showItemsStartIndex, showItemsStartIndex + showItemsCount);

    const changeSelectedValue = (id: string) => {
        if (id !== selectedCurrency) {
            setSelectedCurrency(id);
            setAnimation(id);
            dispatch(resetPaymentTariffUrlRedux());
            setTimeout(() => {
                let ind: number = 0;
                const first: CurrenciesListTypes = _.cloneDeep(list[showItemsStartIndex]);
                const second: CurrenciesListTypes = _.cloneDeep(
                    list.find((e, i) => {
                        if (e.id === id) {
                            ind = +i;
                            return true;
                        }
                    }) || list[0]
                );
                const newList: CurrenciesListTypes[] = _.cloneDeep(list);
                newList[showItemsStartIndex] = second;
                newList[ind] = first;
                cList.current = newList;
                setAnimation("");
            }, 300);
        }
    };
    const changeStartIndex = (type: "increase" | "decrease") => {
        if (type === "increase") {
            if (showItemsStartIndex + showItemsCount < allCurrenciesList.length) {
                setShowItemsStartIndex(showItemsStartIndex + showItemsCount);
            }
        } else {
            if (showItemsStartIndex - showItemsCount > 0) {
                setShowItemsStartIndex(showItemsStartIndex - showItemsCount);
            } else if (showItemsStartIndex - showItemsCount <= 0) {
                setShowItemsStartIndex(0);
            }
        }
    };

    useEffect(() => {
        dispatch(getCryptomusCurrencies({ token }));
    }, []);

    useEffect(() => {
        const handleResize = () =>
            setShowItemsCount(Math.floor((window.innerWidth > 1300 ? 650 : window.innerWidth - (window.innerWidth > 850 ? 500 : 80)) / 160));
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className={styles.currencies_carousel_wrapper}>
            {showItemsStartIndex !== 0 && (
                <div className={`${styles.arrow_btn} ${styles.left}`} onClick={() => changeStartIndex("decrease")}>
                    <ArrowLeftOutlined />
                </div>
            )}
            {currenciesToShow.map(({ id, logo, alt }, i) => (
                <motion.div
                    key={id}
                    className={`${styles.carouser_item} ${selectedCurrency === id && styles.carouser_is_selected} ${
                        animation && selectedCurrency === id && styles.animated_process
                    }`}
                    onClick={() => changeSelectedValue(id)}
                    variants={currency_item_animation}
                    custom={i + 1}
                    initial="hidden"
                    whileInView="visible"
                    title={alt}
                >
                    <img src={logo} alt={alt} />
                    {animation === id && (
                        <div className={styles.animation} style={{ "--i": i * 160 * -1 + "px" } as React.CSSProperties}>
                            <img src={logo} alt={alt} />
                        </div>
                    )}
                </motion.div>
            ))}
            {showItemsStartIndex + showItemsCount < allCurrenciesList.length && (
                <div className={`${styles.arrow_btn} ${styles.right}`} onClick={() => changeStartIndex("increase")}>
                    <ArrowRightOutlined />
                </div>
            )}
        </div>
    );
}

export default CurrenciesCarousel;
