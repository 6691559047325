import mindset from "../../../../assets/images/moreBenefits/mindset.png";
import facebook from "../../../../assets/images/moreBenefits/facebook.png";
import amazon from "../../../../assets/images/moreBenefits/amazon.png";
import ebay from "../../../../assets/images/moreBenefits/ebay.png";
import google from "../../../../assets/images/moreBenefits/google.png";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../store";
import { showNotifyRedux } from "../../../../store/features/notifySlice";
import { constants } from "../../../../assets/constants";
import { useCallback } from "react";
import { resetModalStateToInitialRedux } from "../../../../store/features/modalSlice";
import { useNavigate } from "react-router-dom";
import styles from "./more_benefits.module.scss";

const {
    notifyTypes: { invite },
    paths,
} = constants;

function MoreBenefits() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const copyPromoToClipboard = useCallback(() => {
        dispatch(showNotifyRedux({ type: invite, title: t("notifyMessages.promo_copied"), duration: 2000 }));
        window.navigator.clipboard.writeText("NEW20");
    }, [t]);

    const closeModal = (skip?: boolean) => {
        dispatch(resetModalStateToInitialRedux());
        skip && navigate(paths.account);
    };

    return (
        <div className={styles.more_benefits_main}>
            <div className={styles.more_benefits_images_wrapper}>
                <img src={mindset} alt="mindset" className={styles.mindsetImg} />
                <img src={facebook} alt="facebook" className={styles.facebookImg} />
                <img src={amazon} alt="amazon" className={styles.amazonImg} />
                <img src={ebay} alt="ebay" className={styles.ebayImg} />
                <img src={google} alt="google" className={styles.googleImg} />
            </div>
            <div className={styles.main_content}>
                <p className={styles.benefits_title_first}>{t("modals.more_benefits.benefits_title_first")}</p>
                <p className={styles.benefits_title_second}>
                    <span>{t("modals.more_benefits.benefits_title_second")}</span>
                    <span>{t("modals.more_benefits.benefits_title_third")}</span>
                </p>
                <p className={styles.benefits_sub_title_text}>{t("modals.more_benefits.benefits_sub_title_text")}</p>
                <div className={styles.promo_code_wrapper}>
                    <p className={styles.with_promo_text}>{t("modals.more_benefits.with_promo_text")}</p>
                    <p className={styles.with_promo_btn} onClick={copyPromoToClipboard}>
                        {t("modals.more_benefits.with_promo_btn")}
                    </p>
                </div>
                <div className={styles.skip_start_btns_wrapper}>
                    <p className={styles.start_btn} onClick={() => closeModal()}>
                        {t("modals.more_benefits.get_started")}
                    </p>
                    <p className={styles.skip_btn} onClick={() => closeModal(true)}>
                        {t("modals.more_benefits.skip_for_now")}
                    </p>
                </div>
                <p className={styles.exclusive_text}>{t("modals.more_benefits.exclusive_text")}</p>
            </div>
        </div>
    );
}

export default MoreBenefits;
