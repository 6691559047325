import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { constants } from "../../../../assets/constants";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { helpers } from "../../../../helpers";
import { resetIsMergeOrConvertPageOpenRedux, resetModalStateToInitialRedux, setModalTypeRedux } from "../../../../store/features/modalSlice";
import { TariffListTypes } from "../../../../types";
import {
    buySubscriptionPlanAsync,
    buyTeamPlanAsync,
    checkPromoCodeAsync,
    paymentsTariffAsync,
    paymentsTariffForTeamAsync,
    resetActivePromocodeRedux,
    resetDiscountedTariffListRedux,
    resetPromoCodeErrorRedux,
} from "../../../../store/features/accountSlice";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ReactComponent as OkIcon } from "../../../../assets/images/svg/accountSVG/okIcon.svg";
import { ReactComponent as ErrorIcon } from "../../../../assets/images/svg/accountSVG/closeIcon.svg";
import CurrenciesCarousel from "./CurrenciesCarousel";
import styles from "./pay_for_subscription_plan.module.scss";

const { masqWallet, create_new_team, modalTypes } = constants;

const PayForSubscriptionPlan = ({ tariffData }: { tariffData: TariffListTypes }) => {
    const { name, _id, price, userSeatPrice } = tariffData;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const {
        paymentTariffUrl,
        token,
        promoCodeError,
        user: { balance },
        teams,
        activatedPromocode,
        discountedTariffList,
        activeSub,
        cryptomusCurrenciesList,
    } = useAppSelector((state) => state.account);
    const { tariffList } = useAppSelector((state) => state.tariff);
    const { configs } = useAppSelector((state) => state.modal);
    const [promocode, setPromocode] = useState<string>("");
    const [seats, setSeats] = useState<number>(0);
    const [selectedCurrency, setSelectedCurrency] = useState<string>(masqWallet);

    const isTeam = name.toLowerCase().includes("team");
    const isLight = name.toLowerCase().includes("light");
    const discountedTariff = helpers.getTariffBuyConfigs({
        isTeam,
        isLight,
        tariffList,
        discountedTariffList,
        isDiscount: true,
        annualOrMonthly: "monthly",
    });
    const selectedTariff = helpers.getTariffBuyConfigs({
        isTeam,
        isLight,
        tariffList,
        discountedTariffList,
        isDiscount: false,
        annualOrMonthly: "monthly",
    });

    const finalPrice = () => {
        let fPrice = activatedPromocode ? discountedTariff.price : price;

        if (name.toLowerCase().includes("team") && userSeatPrice) {
            fPrice += seats * userSeatPrice;
        }

        return fPrice;
    };

    const checkPromoCode = () => {
        if (promocode && promocode !== activatedPromocode) {
            dispatch(checkPromoCodeAsync({ token, promocode, type: "check" }));
        }
    };

    const chooseMethodMenuItemsForTeam = useMemo(() => {
        const items = teams.map((team) => ({
            id: team._id,
            value: team.name,
            text: team.name,
        }));
        items.unshift({
            id: create_new_team,
            value: create_new_team,
            text: create_new_team,
        });
        return items;
    }, [teams]);
    const teamForRenewed = teams.find((el) => el._id === configs?.renewTeamId);

    const isBalanceEnoughToBuy = balance >= selectedTariff.price;
    const isRenew = configs?.renewTeamId && configs.renewTeamId !== create_new_team && teamForRenewed?.activeSub?._id;

    const checkingFunds = (): [number, string] => {
        if (selectedCurrency === masqWallet || selectedTariff.price === 0) {
            if (selectedTariff.price <= balance || selectedTariff.price === 0) {
                return [1, t("buy_change_sub.pay") + ""];
            } else {
                return [2, t("buy_change_sub.top_up_and_pay") + ""];
            }
        } else {
            return [3, t("buy_change_sub.pay_online") + ""];
        }
    };
    const handleOpenModal = () => dispatch(setModalTypeRedux({ type: modalTypes.useQrCode, configs: { selectedCurrencyName: selectedCurrency } }));

    // const [annualOrMonthly, setAnnualOrMonthly] = useState<"annual" | "monthly">(name.toLowerCase().includes("yearly") ? "annual" : "monthly");
    // const changeBillingCycle = (v: "monthly" | "annual") => {
    //     dispatch(resetPaymentTariffUrlRedux());
    //     setAnnualOrMonthly(v);
    // };

    const changeSeats = (type: "increase" | "decrease") => {
        setSeats((prevSeats) => {
            if (type === "increase") {
                return prevSeats + 1;
            } else if (type === "decrease" && prevSeats !== 0) {
                return prevSeats - 1;
            }
            return prevSeats;
        });
        finalPrice();
    };

    const changePromoCodeValue = (promocode: string) => {
        promoCodeError && dispatch(resetPromoCodeErrorRedux());
        activatedPromocode && dispatch(resetActivePromocodeRedux());
        setPromocode(promocode);
    };

    const closePaymentFn = () => dispatch(resetModalStateToInitialRedux());
    const handleBuySub = (sc: number) => {
        if (paymentTariffUrl || selectedCurrency === masqWallet) {
            if (sc === 1) {
                if (name.toLocaleLowerCase().includes("team")) {
                    dispatch(
                        buyTeamPlanAsync({
                            token,
                            tariffId: _id,
                            promoCode: activatedPromocode,
                            closePaymentPageFn: closePaymentFn,
                            subName: name,
                            seatCount: seats,
                        })
                    );
                } else {
                    dispatch(
                        buySubscriptionPlanAsync({
                            token,
                            subId: _id,
                            isTeam,
                            promoCode: activatedPromocode,
                            closePaymentPageFn: closePaymentFn,
                            subName: name,
                        })
                    );
                }
            } else {
                window.open(paymentTariffUrl, "_blank");
            }
        }
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (selectedCurrency !== masqWallet || selectedTariff.price > balance) {
            timeout = setTimeout(() => {
                if (selectedTariff.isTeam) {
                    dispatch(
                        paymentsTariffForTeamAsync({
                            token,
                            tariffId: selectedTariff._id,
                            currency: selectedCurrency === masqWallet ? "" : selectedCurrency,
                            seatCount: seats,
                            promoCode: activatedPromocode,
                        })
                    );
                } else {
                    dispatch(
                        paymentsTariffAsync({
                            token,
                            tariffId: selectedTariff._id,
                            currency: selectedCurrency === masqWallet ? "" : selectedCurrency,
                            promoCode: activatedPromocode,
                        })
                    );
                }
            }, 500);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [selectedCurrency, activatedPromocode, seats]);

    useEffect(() => {
        return () => {
            dispatch(resetDiscountedTariffListRedux());
            dispatch(resetIsMergeOrConvertPageOpenRedux());
        };
    }, []);

    useEffect(() => {
        if (isRenew && teamForRenewed.activeSub?._id) {
            setSeats(teamForRenewed.userLimit - 1);
        }
    }, [isRenew]);

    return (
        <div className={styles.pay_for_sub_wrapper}>
            <div className={styles.pay_for_sub_main}>
                <div className={styles.title_subtitle}>
                    <div className={styles.back_btn} onClick={closePaymentFn}>
                        <ArrowLeftOutlined />
                        <span>
                            {t(`sundry.back_to`)} {t("sundry.tariff_plan")}
                        </span>
                    </div>
                    <p className={styles.subtitle}>{t("buy_change_sub.subtitle")}</p>
                    <p className={styles.title}>
                        <span className={styles.team_name}>
                            {configs?.renewTeamId
                                ? configs?.renewTeamId === create_new_team
                                    ? t("buy_change_sub.create_new_team")
                                    : t("buy_change_sub.renew_team")
                                : name.toLowerCase() === activeSub.type.toLowerCase()
                                ? t("buy_change_sub.renew")
                                : t("buy_change_sub.purchase")}
                        </span>
                        <span className={styles.purchase_title_sec}>
                            {configs?.renewTeamId ? (
                                configs?.renewTeamId !== create_new_team ? (
                                    <>
                                        <div className={styles.team_item_logo}>{/* <PersonOutlineOutlinedIcon /> */}</div>
                                        <span>{chooseMethodMenuItemsForTeam.find((el) => el.id === configs?.renewTeamId)?.value}</span>
                                    </>
                                ) : (
                                    ""
                                )
                            ) : (
                                helpers.patchSubscriptionName(t, name, true)
                            )}
                        </span>
                    </p>
                </div>
            </div>
            <div className={styles.sub_payment_wrapper}>
                <div className={styles.payment_left_side}>
                    <div className={styles.title_subtitle}>
                        <p className={styles.subtitle}>{t("buy_change_sub.you_choosed")}</p>
                        <p className={styles.title}>
                            {name} {t("sundry.subscription")}
                        </p>
                    </div>
                    {name.toLowerCase().includes("team") && (
                        <div className={styles.seats_wrapper}>
                            <p className={styles.seats_label}>{t("buy_change_sub.seats_number")}</p>
                            <div className={styles.seats_input_wrapper}>
                                {isRenew ? (
                                    <span></span>
                                ) : (
                                    <span className={styles.plus_minus} onClick={() => changeSeats("decrease")}>
                                        -
                                    </span>
                                )}
                                <span className={styles.seats}>{seats}</span>
                                {isRenew ? (
                                    <span></span>
                                ) : (
                                    <span className={styles.plus_minus} onClick={() => changeSeats("increase")}>
                                        +
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={styles.pay_method_wrapper}>
                        <p className={styles.title}>{t("buy_change_sub.payments_methods")}</p>
                        <CurrenciesCarousel selectedCurrency={selectedCurrency} setSelectedCurrency={(c: string) => setSelectedCurrency(c)} />
                    </div>
                </div>
                <div className={styles.right_side}>
                    <div className={styles.subtitle_title}>
                        <p className={styles.title}>{t("buy_change_sub.subtotal")}</p>
                        <div className={styles.price_subtitle}>
                            <p className={styles.sub_cost}>{t("buy_change_sub.sub_cost")}</p>
                            <p className={styles.price}>
                                {finalPrice()}$
                                {activatedPromocode && (
                                    <span className={styles.discounted}>{price + seats * (selectedTariff.userSeatPrice || 0)}</span>
                                )}
                            </p>
                        </div>
                    </div>
                    <div className={styles.promo_wrapper}>
                        <p className={styles.label}>{t("sundry.promocode")}</p>
                        <div className={styles.inp_wrapper}>
                            <input
                                type="text"
                                placeholder={t("account.enter_promo")}
                                value={promocode}
                                onChange={(e) => changePromoCodeValue(e.target.value)}
                            />
                            {!activatedPromocode && !promoCodeError && (
                                <button className={styles.activate_btn} onClick={checkPromoCode}>
                                    {t("sundry.activate")}
                                </button>
                            )}
                            {activatedPromocode && <OkIcon />}
                            {promoCodeError && <ErrorIcon />}
                        </div>
                    </div>
                    <ul className={styles.info_wrapper}>
                        <li>{t("buy_change_sub.sub_text_one")}</li>
                        <li>{t("buy_change_sub.sub_text_two")}</li>
                    </ul>
                    <button className={styles.btn} onClick={() => handleBuySub(checkingFunds()[0])} disabled={!cryptomusCurrenciesList.length}>
                        {checkingFunds()[1]}
                    </button>
                    {(!isBalanceEnoughToBuy || selectedCurrency !== masqWallet) && paymentTariffUrl && (
                        <button className={styles.use_qr_btn} onClick={handleOpenModal}>
                            {t("buy_change_sub.use_qr_code")}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PayForSubscriptionPlan;
