import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { resetAccountStateToInitialRedux } from "../features/accountSlice";
import { resetByDefaultSettingsRedux } from "../features/settingsSlice";
import { resetModalStateToInitialRedux } from "../features/modalSlice";
import { resetTariffToInitialRedux } from "../features/tariffSlice";

const apiMiddleware: Middleware = (store) => (next) => (action: any) => {
    if (action.type.endsWith("/rejected") && action?.error?.message?.toLowerCase().includes("unauthorized")) {
        localStorage.removeItem("token");
        store.dispatch(resetAccountStateToInitialRedux());
        store.dispatch(resetModalStateToInitialRedux());
        store.dispatch(resetByDefaultSettingsRedux());
        store.dispatch(resetTariffToInitialRedux());
        return;
    }
    return next(action);
};

export default apiMiddleware;
