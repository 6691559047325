import Listeners from "./components/Listeners";
import Modals from "./helperComponents/Modals";
import Notify from "./helperComponents/Notify";
import Router from "./router";

function App() {
    return (
        <>
            <Router />
            <Listeners />
            <Notify />
            <Modals />
        </>
    );
}

export default App;
