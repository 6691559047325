import * as React from "react";
const EbayIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={81} height={40} fill="none">
        <path
            fill="#14161F"
            d="M48.244 20.498c-2.56.082-4.161.536-4.161 2.213 0 1.087.878 2.255 3.09 2.255 2.965 0 4.552-1.595 4.552-4.22v-.289l-3.48.041Zm6.32 3.464.097 2.336h-2.63a15.074 15.074 0 0 1-.098-1.76c-1.42 1.732-3.118 2.227-5.47 2.227-3.48 0-5.345-1.815-5.345-3.918 0-3.052 2.534-4.124 6.953-4.22 1.203-.027 2.553-.027 3.667-.027v-.302c0-2.035-1.323-2.867-3.618-2.867-1.698 0-2.964.701-3.09 1.897h-2.978c.32-2.996 3.507-3.753 6.304-3.753 3.375 0 6.194 1.182 6.194 4.688v5.705l.014-.006ZM23.762 19.014c-.112-2.612-2.026-3.588-4.064-3.588-2.2 0-3.967 1.1-4.273 3.588h8.337Zm-8.393 1.856c.153 2.543 1.921 4.04 4.357 4.04 1.684 0 3.187-.666 3.688-2.157h2.923c-.57 2.997-3.8 4.013-6.57 4.013-5.053 0-7.28-2.75-7.28-6.446 0-4.083 2.311-6.764 7.336-6.764 3.994 0 6.917 2.062 6.917 6.572v.742H15.37Z"
        />
        <path
            fill="#14161F"
            d="M33.992 24.856c2.63 0 4.426-1.87 4.426-4.688 0-2.819-1.796-4.688-4.426-4.688-2.617 0-4.426 1.869-4.426 4.688 0 2.818 1.809 4.688 4.426 4.688ZM26.739 8.647h2.825v7.025c1.392-1.636 3.299-2.104 5.177-2.104 3.16 0 6.654 2.104 6.654 6.64 0 3.8-2.784 6.572-6.71 6.572-2.06 0-3.98-.729-5.177-2.172 0 .577-.027 1.154-.097 1.704h-2.783l.097-2.886V8.647h.014ZM68.972 14.023 60.217 31h-3.172l2.519-4.715-6.584-12.262h3.313l4.844 9.581 4.83-9.581h3.005Z"
        />
    </svg>
);
export default EbayIcon;
