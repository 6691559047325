import { ReactComponent as CrownIcon } from "../../../assets/images/svg/tariffSVG/crown.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useEffect, useState } from "react";
import { constants } from "../../../assets/constants";
import { setHelperModalMethodToMerge, setModalTypeRedux } from "../../../store/features/modalSlice";
import { TariffListTypes } from "../../../types";
import _ from "lodash";
import BackToSomePage from "../../../helperComponents/BackToSomePage";
import PayForSubscriptionPlan from "./PayForSubscriptionPlan";
import styles from "./tariff_plan.module.scss";

const tariffDataInitialState = [
    {
        _id: "",
        name: "",
        price: 0,
        duration: 0,
        userLimit: 0,
        profileLimit: 0,
        createAt: 0,
        weight: 0,
        folderLimit: 0,
        isTeam: false,
        permissions: {
            profilePreset: false,
            automations: false,
            exportProfile: false,
            exportCookie: false,
            tags: false,
            mobileProfile: false,
            proxy: false,
            proxyChecker: false,
            importCookie: false,
            shareProfile: false,
            shareProfileFolder: false,
        },
        profilesUnlimited: false,
        updateAt: 0,
        userLimitExtendable: false,
        __v: 0,
        isBase: false,
        isPublic: false,
        userSeatPrice: 0,
    },
];

const TariffPlan = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { activeSub, teams } = useAppSelector((state) => state.account);
    const { tariffList } = useAppSelector((state) => state.tariff);
    const { isMergeOrConvertPageOpen, moreBenefitsShow } = useAppSelector((state) => state.modal);
    const { paths, modalTypes, create_new_team } = constants;

    const [tariffData, setTariffData] = useState<TariffListTypes[]>(tariffDataInitialState);

    const filteredTariffList = _.cloneDeep(tariffList).sort((a, b) => a.price - b.price);

    const forOpenPaymentsPage = (subscription: TariffListTypes) => {
        const { name, weight, _id } = subscription;
        if (name.toLowerCase().includes("team") && !teams.length) {
            dispatch(setHelperModalMethodToMerge());
        } else if (name.toLowerCase().includes("team")) {
            dispatch(
                setModalTypeRedux({
                    type: modalTypes.chooseSubPlanMethod,
                    configs: {
                        renewTeamId: create_new_team,
                    },
                })
            );
        } else if (activeSub.type && activeSub.type.toLowerCase() !== name.toLowerCase() && !!activeSub.tariffWeight) {
            dispatch(
                setModalTypeRedux({
                    type: modalTypes.convertSubs,
                    configs: { weight, tariffId: _id, subName: name },
                })
            );
        } else {
            dispatch(setHelperModalMethodToMerge());
        }

        setTariffData([subscription]);
    };

    useEffect(() => {
        const teamName = localStorage.getItem("try_now_value");
        if (teamName) {
            const subscription = filteredTariffList.find((el) => el.name.toLowerCase().includes(teamName));
            subscription && (!activeSub || (activeSub && activeSub.type.toLowerCase().includes("free"))) && forOpenPaymentsPage(subscription);
            localStorage.removeItem("try_now_value");
        }
        if (!moreBenefitsShow && (!activeSub || activeSub.type?.toLowerCase().includes("free"))) {
            dispatch(
                setModalTypeRedux({
                    type: modalTypes.moreBenefitsShow,
                    moreBenefitsShow: "shown",
                })
            );
        }
    }, []);

    // The bellow ones for switcher
    // const findTheHighestPrices = tariffList
    //     .filter((item) => item.name.toLowerCase() === "team" || item.name.toLowerCase() === "team yearly")
    //     .sort((a, b) => a.price - b.price);
    // const savePricePercent = Math.round(100 - (findTheHighestPrices[1]?.price * 100) / (findTheHighestPrices[0]?.price * 12));
    return (
        <div className={styles.tariff_wrapper}>
            {!isMergeOrConvertPageOpen ? (
                <>
                    <div className={styles.title_main}>
                        <div className={styles.title_subtitle}>
                            <BackToSomePage path={paths.account} page={t("sundry.account")} />
                            <p className={styles.subtitle}>{t("tariff.main_tariff")}</p>
                            <p className={styles.title}>{t("tariff.tariff_plan")}</p>
                        </div>
                        <div className={styles.tariff_logo}>
                            <div className={styles.icon}>
                                <CrownIcon />
                            </div>
                            <div className={styles.tariff_info}>
                                <p className={styles.tariff_plan}>{t("tariff.tariff_plan")}</p>
                                <p className={styles.tariff}>{activeSub.type}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.sub_plans_wrapper}>
                        <div className={styles.sub_plans_main}>
                            {/* This is monthly or yearly switcher */}
                            {/* <div className={styles.sub_switcher}>
                        <div className={styles.switcher_date} onClick={() => changeData("buyYearly", false)}>
                            <span className={`${!tariffData.buyYearly && styles.active_billing}`}>{t("tariff.monthly")}</span>
                        </div>
                        <div className={styles.switcher_date} onClick={() => changeData("buyYearly", true)}>
                            <span>
                                <span className={`${tariffData.buyYearly && styles.active_billing}`}>{t("tariff.yearly")}</span>
                                <span className={styles.save_to_up_info}>
                                    {" ("}
                                    {t("tariff.save_up")}
                                    {savePricePercent + "%"}
                                    {")"}
                                </span>
                            </span>
                        </div>
                        <div className={`${styles.switcher_clicked_btn} ${tariffData.buyYearly && styles.yearly}`}></div>
                    </div> */}
                            <div className={styles.sub_plans}>
                                {filteredTariffList.map((subscription) => {
                                    const { _id, name, price, duration, folderLimit, userLimit, profilesUnlimited } = subscription;
                                    return (
                                        <div
                                            key={_id}
                                            className={`${styles.subscription} ${name.toLowerCase().includes("pro") && styles.pro_sub}`}
                                            onClick={() => forOpenPaymentsPage(subscription)}
                                        >
                                            <div className={styles.sub_main}>
                                                <div className={styles.name_price}>
                                                    <p className={styles.name}>{name}</p>
                                                    <p className={styles.price}>
                                                        ${price} / {duration} {t("tariff.days")}
                                                    </p>
                                                </div>
                                                <ul className={styles.profiles_info}>
                                                    {profilesUnlimited && <li className={styles.each_profile}>∞ {t("tariff.profiles")}</li>}
                                                    <li className={styles.each_profile}>
                                                        {folderLimit} {t("subscription.folders")}
                                                    </li>
                                                    <li className={styles.each_profile}>
                                                        {userLimit} {t("subscription.team_members")}
                                                    </li>
                                                    {name.toLowerCase().includes("light") && (
                                                        <>
                                                            <li className={styles.each_profile}>
                                                                {userLimit} {t("tariff.light.text_one")}
                                                            </li>
                                                            <li className={styles.each_profile}>
                                                                {userLimit} {t("tariff.light.text_two")}
                                                            </li>
                                                            <li className={styles.each_profile}>
                                                                {userLimit} {t("tariff.light.text_three")}
                                                            </li>
                                                        </>
                                                    )}
                                                    {name.toLowerCase().includes("pro") && (
                                                        <>
                                                            <li className={styles.each_profile}>
                                                                {userLimit} {t("tariff.pro.text_one")}
                                                            </li>
                                                            <li className={styles.each_profile}>
                                                                {userLimit} {t("tariff.pro.text_two")}
                                                            </li>
                                                            <li className={styles.each_profile}>
                                                                {userLimit} {t("tariff.pro.text_three")}
                                                            </li>
                                                        </>
                                                    )}
                                                    {name.toLowerCase().includes("team") && (
                                                        <>
                                                            <li className={styles.each_profile}>
                                                                {userLimit} {t("tariff.team.text_one")}
                                                            </li>
                                                            <li className={styles.each_profile}>
                                                                {userLimit} {t("tariff.team.text_two")}
                                                            </li>
                                                            <li className={styles.each_profile}>
                                                                {userLimit} {t("tariff.team.text_three")}
                                                            </li>
                                                        </>
                                                    )}
                                                </ul>
                                                <button className={styles.change_buy_btn}>
                                                    {name.toLowerCase().includes("team") && !!teams.length
                                                        ? t("buy_change_sub.renew_or_create_team")
                                                        : activeSub.tariffId === _id
                                                        ? t("buy_change_sub.renew")
                                                        : activeSub.activated && !name.toLowerCase().includes("team") && !!activeSub.tariffWeight
                                                        ? t("buy_change_sub.change_plan")
                                                        : t("buy_change_sub.choose_plan")}
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <PayForSubscriptionPlan tariffData={tariffData[0]} />
            )}
        </div>
    );
};

export default TariffPlan;
