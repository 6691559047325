import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { constants } from "../../../../assets/constants";
import { SignUpPagesProps } from "../../../../types";
import { useAppSelector, useAppDispatch } from "../../../../store";
import Input from "../../../../helperComponents/Input";
import styles from "./username_email.module.scss";
import { registrationAsync } from "../../../../store/features/signupSlice";

const UsernameEmail = ({ formData, changeFormData }: SignUpPagesProps) => {
    const { t } = useTranslation();
    const { paths } = constants;
    const { nameError, emailError } = useAppSelector((state) => state.signUp);
    const dispatch = useAppDispatch();

    const isDataCorrect: boolean = !!(
        !nameError &&
        !emailError &&
        formData.name &&
        formData.email &&
        formData.password &&
        !formData.nameLocalError &&
        !formData.emailLocalError &&
        !formData.passwordLocalError
    );

    const register = () => {
        const { name, email, password } = formData;
        const changePageFn = changeFormData({ type: "page", value: "1" });
        dispatch(registrationAsync({ formData: { name, email, password }, changePageFn }));
    };

    return (
        <>
            <p className={styles.title}>{t("signUp.create_acc")}</p>
            <div className={styles.inputs_wrapper}>
                <Input
                    label={t("signUp.your_email")}
                    type="email"
                    placeholder={t("signUp.email_placeholder")}
                    value={formData.email}
                    onChange={changeFormData}
                    name="email"
                    isError={formData.emailLocalError || emailError}
                />
                <Input
                    label={t("sundry.username")}
                    type="text"
                    placeholder={t("signIn.username_placeholder")}
                    value={formData.name}
                    onChange={changeFormData}
                    name="name"
                    isError={formData.nameLocalError || nameError}
                />
                <Input
                    label={t("sundry.password")}
                    type="password"
                    placeholder={t("signUp.enter_password")}
                    value={formData.password}
                    onChange={changeFormData}
                    name="password"
                    isError={formData.passwordLocalError}
                />
            </div>
            <button className={styles.sign_up_btn} onClick={register} disabled={!isDataCorrect}>
                {t("signUp.create_acc")}
            </button>
            <div className={styles.have_acc}>
                <span>{t("signUp.have_an_acc")}</span>
                <Link to={paths.signIn}>{t("sundry.signIn")}</Link>
            </div>
        </>
    );
};

export default UsernameEmail;
