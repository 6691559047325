import { ReactComponent as LogoutIcon } from "../../../assets/images/svg/accountSVG/logout.svg";
import { ReactComponent as WalletIcon } from "../../../assets/images/svg/accountSVG/wallet.svg";
import { Link } from "react-router-dom";
import { constants } from "../../../assets/constants";
import MASQLogo from "../../../helperComponents/MASQLogo";
import styles from "./header.module.scss";
import { useAppDispatch, useAppSelector } from "../../../store";
import { logOutAsync, resetByDefaultAccountRedux } from "../../../store/features/accountSlice";

const Header = () => {
    const { paths } = constants;
    const { user, token } = useAppSelector((state) => state.account);
    const dispatch = useAppDispatch();

    const logOut = () => {
        dispatch(resetByDefaultAccountRedux());
        dispatch(logOutAsync({ token }));
    };

    return (
        <header className={styles.private_header}>
            <div className={styles.header_main}>
                <Link to={paths.home}>
                    <MASQLogo isWhite={false} />
                </Link>
                <div className={styles.balance_info}>
                    <p className={styles.balance}>
                        <WalletIcon />
                        {user.balance} USD
                    </p>
                    <p className={styles.userInfo}>
                        <span className={styles.user_icon}>{user.name[0]}</span>
                        <span className={styles.user_name}>{user.name}</span>
                    </p>
                    <button className={styles.logout} onClick={logOut}>
                        <LogoutIcon />
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
