import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardsDataTypes } from "../../../../types";
import { motion } from "framer-motion";
import { items_op_scale_animation } from "../../../../assets/animations";
import GrainyGradient from "../../../../helperComponents/GrainyGradient";
import styles from "./advantages.module.scss";
import SectionTitleWithBorder from "../../../../helperComponents/SectionTitleWithBorder";

const Advantages = () => {
    const { t } = useTranslation();
    const cardsData = useMemo<CardsDataTypes[]>(
        () => [
            { id: "1", title: t("advantages.masqshfield"), text: t("advantages.masqshfield_text") },
            { id: "2", title: t("advantages.teamwork"), text: t("advantages.teamwork_text") },
            { id: "3", title: t("advantages.performance"), text: t("advantages.performance_text") },
            { id: "4", title: t("advantages.regular"), text: t("advantages.regular_text") },
            { id: "5", title: t("advantages.ease"), text: t("advantages.ease_text") },
            { id: "6", title: t("advantages.security"), text: t("advantages.security_text") },
        ],
        [t]
    );
    return (
        <div className={styles.advantages_wrapper}>
            <GrainyGradient />
            <div className={styles.advantages_main}>
                <SectionTitleWithBorder title={t("advantages.advantages")} borderedText={t("advantages.of_masq")} />
                <div className={styles.cards_wrapper}>
                    {cardsData.map(({ id, title, text }, i) => (
                        <motion.div
                            initial="hidden"
                            viewport={{ once: true }}
                            whileInView="visible"
                            variants={items_op_scale_animation}
                            custom={i}
                            className={styles.card}
                            key={id}
                        >
                            <p className={styles.cards_title}>{title}</p>
                            <p className={styles.cards_text}>{text}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Advantages;
