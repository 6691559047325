import React from "react";
import styles from "./terms_and_privacy.module.scss";

function TermsAndPrivacy() {
    return (
        <div className={styles.termsAndPrivacyWrapper}>
            <div className={styles.privacyPolicyContentWrapper}>
                <h1 className={styles.title}>MASQ Privacy Policy</h1>
                <p className={styles.lastUpdate}>Last updated: June 24, 2024</p>
                <h3>
                    This Privacy Policy (“Policy”) describes the order in which MASQ (hereinafter referred to as the "Service," “we,” “us,” and
                    “our”), collects, uses and discloses information, when you, as the current or potential user (hereinafter referred to as the
                    “User,” “you”) access or use the Service, and our website at https://masqad.com/ (hereinafter referred to as the "Website"), or
                    when you otherwise interact with us
                </h3>
                <h3>
                    This Policy describes how we collect and use the Personal Data you provide. It also describes available options regarding our use
                    of your Personal Data and your access to information.However, even when we use Personal Data, we generally have no way of actually
                    identifying you as an individual, and our users are essentially anonymous to us. We respect your privacy and We take its
                    protection seriously
                </h3>
                <h3>
                    Please read the Terms of Use and our Policy posted on the Website before using the Service. As used in these Terms of Use, the
                    word “Services” applies to the online services provided to you by MASQ through its software applications, websites, and APIs
                </h3>
                <h3>
                    Any use of the Service by you signifies your full and unconditional acceptance of its terms. If you do not accept the terms in
                    full, you are not entitled to use the Service for any purpose
                </h3>
                <h3>
                    We may change these Policy at any time without notice, effective upon their posting. Your continued use of the Service shall be
                    considered your acceptance of the revised Policy. If you do not agree to these Policy, you must discontinue use of this Service
                </h3>
                <h3>
                    In case you have any questions, please contact us:
                    <a href="mailto:masqad@masqad.com">masqad@masqad.com</a>
                </h3>
                <h3>
                    People under 18 (or the legal age in your jurisdiction) are not permitted to use MASQ on their own, and so this Policy makes no
                    provision for their use of MASQ
                </h3>
                <h3>Depending on your use of the Service, we need to collect and process the following information:</h3>
                <ul className={styles.list}>
                    <li>
                        information (such as a username, a valid email address and country of residence) that you provide by completing our forms,
                        including if you register as a user of the Service, subscribe to our newsletters or request any information;
                    </li>
                    <li>your login and password details, in connection with the account sign-in process;</li>
                    <li>details of any requests or transactions you make through the Service;</li>
                    <li>
                        information about your activity on and interaction with MASQ, such as your IP address and the type of device or browser you
                        use;
                    </li>
                    <li>
                        communications you send to us (for example, when you ask for support, send us questions or comments, or report a problem);
                    </li>
                    <li>information that you submit to MASQ in the form of comments, contributions to discussions, or messages to other users</li>
                </ul>
                <h3>Any data you provide will not be publicly displayed or revealed to other users</h3>
                <h2>Uses of Your Personal Data</h2>
                <h3>We will use the personal information you provide to:</h3>
                <ul>
                    <li>identify you when you sign in to your account;</li>
                    <li>enable us to provide you with the Services;</li>
                    <li>send you marketing communications we think you may find useful, but only in accordance with your email preferences;</li>
                    <li>administer your account with us;</li>
                    <li>enable us to contact you regarding any question you make through the Service;</li>
                    <li>report on the matters relating to your personal transactions;</li>
                    <li>analyze the use of the Services and the people visiting to improve our content and Services;</li>
                    <li>use for other purposes that we may disclose to you when we request your information</li>
                </ul>
                <h3>
                    We take securing your data and preserving your privacy very seriously. We never post anything to your Facebook, Twitter, or other
                    third-party accounts without your permission. We do not and will not sell your data
                </h3>
                <h3>
                    To modify or delete the personal information you have provided to us, please log in and update your profile or contact customer
                    support. We may retain certain information as required by law or for necessary business purposes
                </h3>
                <h2>Access to information</h2>
                <h3>
                    You have the right and ability to make changes to your personal data. You can do this to the extent allowed by the Service through
                    your account
                </h3>
                <h2>Disclosure of your data</h2>
                <h3>
                    We will not disclose your personal information to third parties except as required by law. We reserve the right to disclose your
                    personal data to fulfill any legal obligations or orders of authorized government authorities
                </h3>
                <h3>
                    On request, we will give you a copy of all the personal information about you that we hold. This information is subject to a fee
                    not exceeding the prescribed fee permitted by law
                </h3>
                <h2>Changes to the privacy policy</h2>
                <h3>
                    Any possible changes to the rules and conditions of storing personal information are governed by our general Terms of Use. On this
                    page, you can always familiarize yourself with the latest version of these provisions
                </h3>
                <h2>Use of cookies</h2>
                <h3>
                    We may collect information about your internet usage through the use of cookies. Cookies help us improve the Service and make your
                    experience more enjoyable. We use cookies to identify you as a registered user of the Service and to store various information
                    about your account to avoid re-entering this information
                </h3>
            </div>
            <div className={styles.termsOfUseContentWrapper}>
                <h1>Terms of Use</h1>
                <p className={styles.lastUpdate}>Last updated: June 24, 2024</p>
                <h3>
                    These Terms of Use constitute a legal agreement between you, as the current or potential user (hereinafter referred to as the
                    “User,” “you”), and us, MASQ (hereinafter referred to as the "Service," “we,” “us,” and “our”), and govern your use of the
                    services provided by the Service. Please read these Terms of Use and our Privacy Policy posted on the website{" "}
                    <a href="https://masqad.com/">https://masqad.com/</a>
                    (hereinafter referred to as the "Website") before using the Service. As used in these Terms of Use, the word “Services” applies to
                    the online services provided to you by MASQ through its software applications, websites, and APIs
                </h3>
                <h3>
                    Any use of the Service by you signifies your full and unconditional acceptance of its terms. If you do not accept the terms in
                    full, you are not entitled to use the Service for any purpose. We may change these Terms of Use at any time without notice,
                    effective upon their posting. Your continued use of the Service shall be considered your acceptance of the revised Terms of Use.
                    If you do not agree to these Terms of Use, you must discontinue use of this Service
                </h3>
                <h3>
                    In case you have any questions, please contact us: <a href="mailto:masqad@masqad.com">masqad@masqad.com</a>
                </h3>
                <h2>Account</h2>
                <h3>
                    The Service is available for download solely for use by end users in accordance with these Terms of Use. To access the Service's
                    features, you will need to create an account. You will be required to provide a username and a valid email address, to which a
                    confirmation code will be sent. After entering the confirmation code, the account will be created. The account will be available
                    for use on the website and with the application installed on one of your devices
                </h3>
                <h3>
                    You are personally responsible for maintaining the confidentiality of your login credentials. Once the registration process is
                    complete, you can log in to your personal account using the email address and password specified during account creation. The
                    information you provide must be accurate, current, and complete
                </h3>
                <h3>
                    If you lose your personal account password, you can reset it if you have access to the email address used for registration. More
                    detailed information can be found on the Website or by contacting customer support at
                    <a href="mailto:masqad@masqad.com">masqad@masqad.com</a>
                </h3>
                <h3>
                    You agree to take all necessary steps to protect your login credentials and keep them confidential. You also agree not to share
                    your login credentials with anyone or allow anyone to use your login credentials or your account
                </h3>
                <h2>Team accounts</h2>
                <h3>
                    Depending on the subscription plan, you may have the right to create accounts for team members, granting a certain number of
                    additional users access to the Service. To grant access to your team members, you must select the appropriate subscription plan
                    and create accounts as described in the "Account" section above
                </h3>
                <h3>
                    Team members are considered users and must register their accounts in accordance with the "Account" section. All other terms of
                    these Terms of Use apply to team members as they do to any other user
                </h3>
                <h3>When canceling the subscription plan, the access rights to the team members' accounts are terminated</h3>
                <h3>
                    More detailed information can be found on the Website or by contacting customer support at
                    <a href="mailto:masqad@masqad.com">masqad@masqad.com</a>
                </h3>
                <h2>Subscription plan</h2>
                <h3>
                    The application is free to download. However, some of its functions are available by subscription for a separate fee. After
                    creating a personal account and downloading the application, you can choose the appropriate subscription plan. The cost and
                    features of each subscription plan are described on the Website
                </h3>
                <h3>
                    If you need individual advice, do not hesitate to contact customer support at masqad@masqad.com to clarify your personal needs
                    regarding subscriptions and further opportunities
                </h3>
                <h3>
                    To the extent permitted by applicable law, we may change the subscription price at any time. Changes in the cost of subscription
                    plans will be reflected on the Website. Posting changes to subscription plans is considered a notification to the user. Please
                    monitor the Website for updates. Notification as described above shall be deemed sufficient. If you do not wish to pay the new
                    prices, you may cancel the applicable subscription before the changes take effect
                </h3>
                <h3>
                    The amount of the subscription plan does not include any applicable taxes. You are responsible for any and all applicable taxes
                </h3>
                <h3>
                    You shall provide a valid credit card or other acceptable method of payment to us and shall take all necessary steps to authorize
                    automatic payment of the subscription fee and service costs
                </h3>
                <h3>
                    By purchasing certain subscriptions, you agree that your subscription may automatically renew. If you do not cancel your
                    subscription, you authorize us to charge you for the renewal of the subscription. The auto-renewal period will be the same as your
                    original subscription period unless otherwise specified on the Website. The cost of the renewal will not exceed the price of the
                    subscription for the previous period, excluding any promotions and discounts, unless we notify you of the price change prior to
                    automatic renewal. You may cancel your subscription in accordance with the cancellation procedures provided for the specific
                    subscription. We will not refund fees charged to your personal account and will not charge fees for canceled subscriptions
                </h3>
                <h3>
                    Your ability to use the Service expires at the end of your paid subscription period. If you do not make a payment for the renewal
                    of the subscription, we will take reasonable measures to notify you. However, we reserve the right to disable or terminate your
                    access to the Service (and we may do this without notice)
                </h3>
                <h2>Deletion of account</h2>
                <h3>
                    You can delete your Account at any time. Service availability will remain in effect after the Account is deleted until the end of
                    the paid period under the Subscription Plan, provided that the subscription is not renewed. We delete all information about your
                    Account, except for the minimum necessary to comply with and fulfill accounting obligations to tax authorities, as required by
                    applicable law
                </h3>
                <h3>
                    Please note that when deleting the Account, all data created in your Account—including sessions, their settings, open tabs, and
                    configuration settings—will be deleted and cannot be restored, even if you later decide to subscribe again. In this case, you will
                    need to register and start all over again
                </h3>
                <h3>
                    Please also note that if you decide to delete your Account, money for the subscription to the Service will not be refunded, unless
                    otherwise specified in the applicable section
                </h3>
                <h2>Intellectual Property Rights</h2>
                <ul>
                    <li>
                        You acknowledge that all right, title, and interest in and to the Services, together with its codes, sequences, derivative
                        works, organization, structure, interfaces, any documentation, data, trade names, trademarks, images, materials or other
                        related materials (collectively, the “Intellectual Property”), is, and at all times shall remain, the sole and exclusive
                        property of MASQ. The Intellectual Property contains trade secrets and proprietary information owned by us and is protected by
                        laws relating to intellectual property. Except the right to use the Service, as expressly provided herein, this Terms of Use
                        does not grant you any rights to, or in, patents, copyrights, database rights, trade secrets, trade names, trademarks (whether
                        registered or unregistered) or any other rights or licenses with respect to the Service
                    </li>
                    <li>
                        You shall not attempt, or directly or indirectly allow any third party to attempt to copy, modify, duplicate, create
                        derivative works from, frame, mirror, republish, reverse compile, disassemble, reverse engineer, download, transmit or
                        distribute all or any portion of the Service in any form or media or by any means
                    </li>
                </ul>
                <h2>Responsible use terms</h2>
                <ul>
                    <li>
                        You agree that you will not use the Services for any purpose that is unlawful or prohibited by these Terms of Use. You agree
                        to follow all applicable local, state, national, and international laws and regulations. You are solely responsible for all
                        acts or omissions that occur while using any Services, including the content of any transmissions you send through the
                        Services and any content you upload or publish using the Services
                    </li>
                    <li>
                        You confirm that you have reached the age at which, according to applicable law, you are permitted to use our Service. You
                        also confirm that you have the ability to take full responsibility for your actions without any limitations. If you are using
                        the Service on behalf of a legal entity, you confirm that you have all necessary authority to do so under applicable law. You
                        must immediately cease using the Service if you violate any of these conditions
                    </li>
                    <li>
                        You are entirely responsible for the security of all your MASQ Accounts. You are also responsible for any and all activities
                        that occur under all your MASQ Accounts. Certain features of the Services may allow you to publish or send content that can be
                        viewed by others (“User Generated Content”). You agree that MASQ is not liable for User Generated Content that is provided by
                        others. MASQ has no duty to pre-screen User Generated Content, but MASQ has the right to refuse to post, edit, or deliver User
                        Generated Content. MASQ reserves the right to remove User Generated Content for any reason, but MASQ is not responsible for
                        any failure or delay in removing such material. MASQ reserves the right to block any user’s access to any content, website or
                        web page at our sole discretion
                    </li>
                    <li>
                        We do not claim ownership of any User Generated Content. However, by submitting User Generated Content on any Service,
                        including any ideas, concepts, know-how, or techniques described therein, you consent to MASQ’s unrestricted use of those
                        items
                    </li>
                </ul>
                <h2>Improper use</h2>
                <h3>You must not use the Service in any way or send anything to us or through the Service or to any User that:</h3>
                <ul>
                    <li>is in violation of any law, statute, regulation or by-law of any applicable jurisdiction;</li>
                    <li>is a fraud, a crime or an illegal action;</li>
                    <li>is outdated or inaccurate information;</li>
                    <li>
                        may contain something that is obscene, indecent, pornographic, vulgar, obscene, racist, sexist, discriminatory, abusive,
                        humiliating, cruel, harassing, threatening, embarrassing, harmful, abusive, hateful, threatening, defamatory, untrue or
                        political in nature;
                    </li>
                    <li>
                        gives out your identity to any other person or organization or misrepresent your relationship with any person or organization;
                    </li>
                    <li>contradicts our interests;</li>
                    <li>
                        conflicts with any individual rule or requirement established by us for the Service with respect to any part of the Service or
                        the Service as a whole;
                    </li>
                    <li>
                        presupposes your use, delivery or transmission of any viruses or anything that may be intended to cause harm, malicious
                        interference, secret theft or alienation of any system information, data or personal information;
                    </li>
                </ul>
                <h3>
                    These rules of conduct and the list of prohibited types of use is open. We reserve the right to determine what behavior We
                    consider to be in violation of these Terms of Use or inappropriate and what measures should be taken
                </h3>
                <h3>As a User, you agree that you are responsible for compliance with any applicable local laws when using our Service</h3>
                <h3>
                    If we establish or receive information from third parties or law enforcement officials about any of the above prohibited uses or
                    other illegal actions by you, we may terminate your access to the Service due to your violation of these Terms of Use, and you
                    will lose the right to a refund paid subscription fees, regardless of the remaining subscription time. You agree to reimburse us
                    for any costs or expenses, including indirect damages, that We or anyone else may suffer or that may arise as a result of such
                    breach or illegal act
                </h3>
                <h3>
                    If we are required to take any action in accordance with applicable law or on the basis of information received from third
                    parties, or we will be notified of any illegal actions by you in relation to and/or when using our Service, or in in accordance
                    with an order and/or a request to us from the competent law enforcement authorities having the authority to do so, we reserve the
                    right to deny you access to the Service without reimbursement of payment for the remaining time of subscription to the Service and
                    not to disclose the reasons for such refusal (in that case, if it corresponds to a law enforcement order or request)
                </h3>
                <h2>Warranties or guarantees</h2>
                <h3>
                    We cannot guarantee that your use of the Services will be without issues. Despite our efforts to deliver the highest quality
                    software and services, we cannot ensure that they will function perfectly at all times or in every aspect. By using this Service,
                    you confirm that you are aware the Service is still under development and is not yet a finished product
                </h3>
                <ul>
                    <li>
                        The Service is provided "as is" with no warranties of any kind. We and our suppliers disclaim all warranties and conditions
                        related to the Services, including implied warranties and conditions of merchantability, fitness for a particular purpose,
                        title, and non-infringement
                    </li>
                    <li>
                        We do not provide the User with any express or implied warranties regarding the Service, including, but not limited to:
                        fitness for a particular purpose, security, accuracy, completeness, performance, system integration, uninterrupted operation,
                        error correction, absence of viruses
                    </li>
                    <li>
                        We do not warrant that the Services will be uninterrupted or error-free, that defects will be corrected, or that the Services
                        or the server hosting them are free of viruses or other harmful components
                    </li>
                    <li>
                        We have the right to temporarily or permanently modify, suspend or terminate the Service (or part thereof, or their specific
                        content) without prior notice
                    </li>
                    <li>
                        We have the right to deny access to the Service to anyone for any reason at any time. Explanations of the reasons for
                        suspension or denial of access may or may not be given depending on circumstances and cannot be demanded from us
                    </li>
                    <li>
                        The Service may not be available in all languages or in all countries, and we make no representations that the functionality
                        of the Service will be appropriate, correct or available for use in any particular location
                    </li>
                    <li>
                        The Services may contain links to other websites (“Third Party Sites”), as well as articles, photographs, text, graphics,
                        pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or
                        originating from third parties (“Third Party Content”). We exercise no editorial control over any such Third Party Content
                        that you access through the Services. We have no control over and no responsibility for Third Party Sites or Third Party
                        Content. Inclusion of, linking to, or permitting the use or installation of any Third Party Site or any Third Party Content
                        does not imply approval or endorsement thereof by us. By using Services, you acknowledge and accept that the Content is
                        published by independent third parties and the views and opinions expressed are solely those of such third parties
                    </li>
                </ul>
                <h2>Confidentiality</h2>
                <h3>
                    In order to use the Service, you may be asked to provide certain personal information. All matters relating to your personal data
                    are governed by our Privacy Policy. We recommend that you carefully read the provisions of this Policy
                </h3>
                <h2>Accuracy, completeness and currentness of information</h2>
                <h3>
                    Materials placed on our website are provided for general information only and cannot be considered and used as exclusive and
                    unequivocal for decision-making, without prior consultation for more accurate, complete and up-to-date primary sources of
                    information in our customer support service at the email address masqad@masqad.com. We are not responsible if the information on
                    our Website is not accurate, complete and up-to-date. Any use of materials from our Website is at your discretion and under your
                    responsibility
                </h3>
                <h3>
                    We reserve the right to change the content of our Website at any time, but We are not obligated to update any information on our
                    Website. You agree that you are personally responsible for monitoring changes to our Website. We do our best to publish any
                    up-to-date information on the Website. We may make functional, procedural or technical changes or improvements to the Website or
                    the Service at any time and without prior notice. We can, but under no circumstances are we obliged to change, add or delete any
                    specific functions
                </h3>
            </div>
        </div>
    );
}

export default TermsAndPrivacy;
