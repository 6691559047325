import { ReactNode } from "react";
import { Collapse } from "antd";
import { CollapseDataTypes, PanelPropsCustom } from "../../types";
import "./collapse_numbers.scss";

const CollapseNumbers = (props: CollapseDataTypes) => {
    return (
        <Collapse
            ghost
            accordion
            activeKey={props.activityKey}
            className="collapse_number_wrapper"
            expandIcon={(panelProps: PanelPropsCustom): ReactNode => <p className="expend_number">{`(0${panelProps.panelKey})`}</p>}
            items={props.list}
        />
    );
};

export default CollapseNumbers;
