import { ReactNode } from "react";
import { Collapse } from "antd";
import { CollapseDataTypes } from "../../types";
import "./collapse_right_icon.scss";

const CollapseRightIcon = (props: CollapseDataTypes) => {
    return (
        <Collapse
            ghost
            accordion
            className="collapse_righ_icon"
            expandIcon={(panelProps): ReactNode => <div className="expend_right_icon">{!panelProps.isActive ? "+" : "-"}</div>}
            items={props.list}
        />
    );
};

export default CollapseRightIcon;
