import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import styles from "./private_pages.module.scss";
import { constants } from "../../assets/constants";

interface PrivatePagesProps {
    children: React.ReactNode;
}

const PrivatePages: React.FC<PrivatePagesProps> = (props) => {
    const location = useLocation();
    const isMainPage = location.pathname === constants.paths.home;

    return (
        <div className={styles.private_pages_wrapper}>
            {!isMainPage && <Header />}
            <div className={styles.private_router_wrapper}>{props.children}</div>
            {!isMainPage && <Footer />}
        </div>
    );
};

export default PrivatePages;
