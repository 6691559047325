import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SignUpPagesProps } from "../../../../types";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { confirmEmailAsync, resetAccountStateToInitialRedux, resetInvalidConfirmCodeRedux } from "../../../../store/features/accountSlice";
import { sendConfirmCodeAsync } from "../../../../store/features/signupSlice";
import { useNavigate } from "react-router-dom";
import { constants } from "../../../../assets/constants";
import styles from "./confirmation.module.scss";
import VerificationInput from "react-verification-input";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";

dayjs.extend(utc);
dayjs.extend(duration);

const Confirmation = ({ email, changeFormData, pageValue }: Omit<SignUpPagesProps, "formData"> & { email: string; pageValue: string }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { paths } = constants;
    const dispatch = useAppDispatch();
    const {
        isConfirmCodeInvalid,
        user: { confirmCodeResendTimeout },
    } = useAppSelector((state) => state.account);

    const [confirmCode, setConfirmCode] = useState("");
    const [remainingTime, setRemainingTime] = useState<string>("");

    const sendConfirmCodeAgain = () => {
        setConfirmCode("");
        dispatch(resetInvalidConfirmCodeRedux());
        dispatch(sendConfirmCodeAsync({ name: email }));
    };

    const toggleConfirmCode = (value: string) => {
        dispatch(resetInvalidConfirmCodeRedux());
        setConfirmCode(value.toUpperCase());
    };

    const confirmConfirmationCode = () => {
        const navigateFn = () => navigate(paths.signIn);
        dispatch(confirmEmailAsync({ confirmCode: confirmCode.trim().toUpperCase(), navigate: navigateFn }));
    };
    const back = () => {
        changeFormData({ type: "page", value: pageValue });
        dispatch(resetAccountStateToInitialRedux());
    };

    useEffect(() => {
        let intervalId: NodeJS.Timer;
        console.log(confirmCodeResendTimeout, "confirmCodeResendTimeout");

        if (confirmCodeResendTimeout) {
            setRemainingTime(" ");
            intervalId = setInterval(() => {
                const currentTime = dayjs().utc();
                const targetDayjsTime = dayjs.utc(confirmCodeResendTimeout);

                const diffInMilliseconds = targetDayjsTime.diff(currentTime);

                if (diffInMilliseconds <= 0) {
                    setRemainingTime("");
                    clearInterval(intervalId);
                } else {
                    const duration = dayjs.duration(diffInMilliseconds);
                    const minutes = duration.minutes();
                    const seconds = duration.seconds();

                    const formattedTime = `${minutes}:${String(seconds).padStart(2, "0")}`;
                    setRemainingTime(formattedTime);
                }
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [confirmCodeResendTimeout]);

    return (
        <div className={styles.confirm_wrapper}>
            <p className={styles.title}>{t("signUp.require_confirm")}</p>
            <p className={styles.text}>
                {t("signUp.require_text")}
                {email}
            </p>
            <div className={styles.code_wrapper}>
                <VerificationInput
                    length={10}
                    autoFocus={true}
                    onChange={toggleConfirmCode}
                    value={confirmCode}
                    onFocus={() => dispatch(resetInvalidConfirmCodeRedux())}
                    classNames={{
                        container: styles.vf_container,
                        character: `${styles.vf_character} ${isConfirmCodeInvalid && styles.active}`,
                        characterInactive: styles.vf_character_inactive,
                        characterSelected: `${styles.vf_character_selected} ${isConfirmCodeInvalid && styles.active}`,
                    }}
                />
            </div>
            <div className={styles.btns_wrapper}>
                <button className={styles.back} onClick={back}>
                    {t("sundry.back")}
                </button>
                <button className={styles.create_acc} onClick={confirmConfirmationCode} disabled={confirmCode.length < 10}>
                    {t("sundry.confirm")}
                </button>
            </div>
            <button className={styles.send_again} onClick={sendConfirmCodeAgain} disabled={!!remainingTime}>
                {t("signUp.send_again")} <span>{remainingTime}</span>
            </button>
        </div>
    );
};

export default Confirmation;
